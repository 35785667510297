import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { FontWeight, Colors, Fontfamily } from "../common";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      backgroundColor="#f4f4f4"
    >
      <Typography
        variant="h1"
        style={{
          fontSize: "10rem",
          color: "#B0BEC5",
          fontWeight: FontWeight.bolder,
        }}
      >
        404
      </Typography>
      <Box display="flex" justifyContent="center" alignItems="center" mt={-8}>
        <Box
          component="span"
          display="inline-block"
          bgcolor="white"
          p={2}
          mx={1}
          borderRadius="4px"
          boxShadow={2}
          style={{ transform: "rotate(-10deg)" }}
        >
          <Typography variant="h4">Sorry!</Typography>
        </Box>
        <Box
          component="span"
          display="inline-block"
          bgcolor="white"
          p={2}
          mx={1}
          borderRadius="4px"
          boxShadow={2}
          style={{ transform: "rotate(10deg)" }}
        >
          <Typography variant="h4">Error!</Typography>
        </Box>
      </Box>
      <Box sx={{ m: 5, textAlign: "center" }}>
        <Typography variant="h6" sx={{ fontWeight: FontWeight.bold }} mt={4}>
          PAGE NOT FOUND
        </Typography>
        <Typography
          variant="body1"
          mt={2}
          sx={{ fontWeight: FontWeight.bold }}
          style={{ fontFamily: Fontfamily.poppins }}
        >
          Why don't you try one of these pages instead?
        </Typography>
      </Box>
      <Box mt={4}>
        <Link to="/">
          <Button
            variant="contained"
            sx={{
              marginRight: "10px",
              bgcolor: "#631e1ef3",
              "&:hover": {
                backgroundColor: Colors.BrightOrange,
              },
            }}
          >
            Go Back Home
          </Button>
        </Link>
        <Link to="/contact">
          <Button
            variant="contained"
            sx={{
              bgcolor: Colors.BrightOrange,
              "&:hover": {
                backgroundColor: "#631e1ef3",
              },
            }}
          >
            Contact Us
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
