import React from "react";
import { Typography, Grid, Box, Button } from "@mui/material";
import assets from "../assets";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";
import { Link } from "react-router-dom";

const expertiseData = [
  { icon: assets.CloudImage, label: "Web / Mobile" },
  { icon: assets.IoTImage, label: "IoT" },
  { icon: assets.AiMiImage, label: "AI / ML" },
  { icon: assets.UXImage, label: "UI / UX" },
  { icon: assets.DevOpsImage, label: "DevOps" },
  { icon: assets.SEOImage, label: "SEO" },
];

const DesignDevelopment = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        py: 4,
        pt: 8,
        pb: 5,
        mb: 5,
        backgroundColor: "#f9fafa",
      }}
    >
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          letterSpacing: "1.5px",
          fontWeight: FontWeight.bold,
          color: Colors.charcoalgray,
          fontSize: FontSize.thirty,
          fontFamily: Fontfamily.poppins,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
        }}
        gutterBottom
      >
        Our Expertise
        {/* EXPERTISE */}
      </Typography>

      <Grid
        container
        spacing={2}
        columnSpacing={1}
        rowSpacing={5}
        justifyContent="center"
        sx={{ mt: 8 }}
      >
        {expertiseData.map((item, index) => (
          <Grid item key={index} spacing={1} xs={6} sm={4} md={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "50%",
                mb: 5,
              }}
            > 
              <img
                src={item.icon}
                alt={item.label}
                width={50}
                height={50}
                loading="lazy"
                style={{
                  objectFit: "contain",
                  marginBottom: "10px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontSize: FontSize.twFour,
                  letterSpacing: "1px",
                  pl: 2,
                  pr: 2,
                  textAlign: "center",
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* <Button
        variant="outlined"
        sx={{
          mt: 4,
          // color: Colors.DarkGray,
          // border: "1px solid #333",
          // "&:hover": {
          //   backgroundColor: "#fd7e14",
          //   color: Colors.WHITE,
          //   border: "1px solid #f68f5c",
          // },
          background: "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)",
          color: Colors.WHITE,
          borderRadius: "50px",
          border:"none",
          // fontSize: "14px",
          fontWeight: "bold",
          textTransform: "none",
          padding: "8px 20px",
          "&:hover": {
          border:"none",
            background: "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
          },
        }}
      >
        <Link
          to="/services"
          style={{
            color: "inherit",
            textDecoration: "none",
            letterSpacing: "2px",
          }}
        >
          EXPLORE
        </Link>
      </Button> */}
    </Box>
  );
};

export default DesignDevelopment;
