import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, IconButton, Divider } from "@mui/material";
import {
  Facebook,
  LinkedIn,
  Instagram,
  LocationOn,
  Phone,
  Email,
} from "@mui/icons-material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
import "../stylesheets/Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <Box component="footer" sx={{ backgroundColor: "#f5f5f5", py: 4, mt: 4 }}>
      <Box sx={{ ml: { md: 10, xs: 3 }, mr: { md: 10, xs: 3 } }}>
        <Grid container spacing={4}>
          {/* Company Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: "600" }}>
              Company
            </Typography>
            <Box mb={2} />
            <Box display="flex" flexDirection="column" gap={2}>
              <Link to="/about" className="footer-link">
                About
              </Link>
              <Link to="/products/testseries" className="footer-link">
                Product
              </Link>
              <Link to="/career" className="footer-link">
                Careers
              </Link>
              <Link to="/termsandconditions" className="footer-link">
                Terms
              </Link>
              <Link to="/privacyandpolicy" className="footer-link">
                Privacy Policy
              </Link>
            </Box>
          </Grid>

          {/* Services Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: "600" }}>
              Services
            </Typography>
            <Box mb={2} />
            <Box display="flex" flexDirection="column" gap={2}>
              <Link to="/services" className="footer-link">
                Static Website Design
              </Link>
              <Link to="/services" className="footer-link">
                Dynamic Website Design
              </Link>
              <Link to="/services" className="footer-link">
                AI & ML
              </Link>
              <Link to="/services" className="footer-link">
                E-Commerce Website
              </Link>
              <Link to="/services" className="footer-link">
                IoT
              </Link>
              <Link to="/services" className="footer-link">
                Mobile App Development
              </Link>
              <Link to="/services" className="footer-link">
                DevOps
              </Link>
              <Link to="/services" className="footer-link">
                Search Engine Optimization (SEO)
              </Link>
            </Box>
          </Grid>

          {/* More Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: "600" }}>
              More
            </Typography>
            <Box mb={2} />
            <Box display="flex" flexDirection="column" gap={2}>
              <Link to="/testimonials" className="footer-link">
                Testimonials
              </Link>
              <Link to="/blogs" className="footer-link">
                Blogs
              </Link>
              <Link to="/contact" className="footer-link">
                Contact
              </Link>
            </Box>
          </Grid>

          {/* Let's Connect Section */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom style={{ fontWeight: "600" }}>
              Let's Connect
            </Typography>
            <Box mb={2} />

            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <LocationOn style={{ color: "#f68f5c" }} />
              <Typography>
                306A, ARG North Avenue, Road 9, Sikar Road, Jaipur (Raj), India
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <Phone style={{ color: "#f68f5c" }} />
              <Typography>+9178.9155.5777</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <Phone style={{ color: "#f68f5c" }} />
              <Typography>+9170.1413.3006</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <Email style={{ color: "#f68f5c" }} />
              <Typography>
                <Link to="mailto:hr@gatiktech.com" className="footer-link">
                hr@gatiktech.com
                </Link>
              </Typography>
            </Box>

            <Box mt={2}>
              <IconButton
                href="https://www.linkedin.com/company/gatikai-technologies/home"
                target="_blank"
                sx={{ color: "#0077B5" }}
                aria-label="Visit GatikAI Technologies LinkedIn page"
              >
                <LinkedIn
                  sx={{
                    borderRadius: "50%",
                    fontSize: { xs: "30px", md: "35px" },
                  }}
                />
              </IconButton>

              <IconButton
                href="https://www.facebook.com/people/GatikAi-Technologies/61553559459205/?mibextid=rS40aB7S9Ucbxw6v"
                target="_blank"
                sx={{ color: "#1877F2" }}
                aria-label="Visit GatikAI Technologies Facebook page"
              >
                <Facebook sx={{ fontSize: { xs: "30px", md: "35px" } }} />
              </IconButton>

              <IconButton
                href="https://www.instagram.com/gatik_tech?igsh=emI4ZXg0OTE3bWVn&utm_source=qr"
                target="_blank"
                sx={{
                  color: "#E4405F",
                }}
                aria-label="Visit GatikAI Technologies Instagram page"
              >
                <Instagram sx={{ fontSize: { xs: "30px", md: "35px" } }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Footer Bottom Section */}
      <Box sx={{ mt: 4, textAlign: "center", mb: 4 }}>
        <Divider sx={{ my: 2, mb: 4, mt: 4 }} />
        <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
          &copy; {currentYear} Copyright by GatikAI Technologies. All Rights
          Reserved.
        </Typography>
      </Box>

      {/* Scroll to Top Button */}
      {isVisible && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            backgroundColor: "#f68f5c",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#000",
            },
          }}
        >
          <KeyboardArrowUpIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default Footer;
