import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { FontWeight, Fontfamily, FontSize, Colors } from "../common";
import { Button } from "antd";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import assets from "../assets";
import Header from "./Header";
import TestSeries from "../assets/images/TestSeries.jpg";
import OurClient from "./OurClient";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Testseries() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%" }}>
     <Header
        title="Test Series: Smarter Institute Management"
        description="Elevate your learning with our Test Series: concise notes, expert lectures, detailed analysis, and a user-friendly test platform. Achieve your academic goals effortlessly!"
        image={TestSeries}
        // link="https://testseries.gatiktech.com/" 
        buttonText="Join The Test Series"
        link="https://testseries.gatiktech.com/"
        target="_blank"
      />

      <Box sx={{ pt: 5, minHeight: "100vh",  ml: { xs: 3, md: 10 }, mr: { xs: 3, md: 10 }, }}> 
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid item xs={12} md={6} lg={6} order={{ xs: 2, md: 1 }}>
            <Box
              component="img"
              src={assets.testseries1}
              alt="Web / Mobile pic"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: { xs: 0, md: 5 },
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
                gutterBottom
              >
                The Smart Choice for Institutes!
              </Typography>
              <p
                style={{
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                }}
              >
                Test Series empowers institute owners by streamlining the exam
                preparation and management process. With features such as
                customizable multiple-choice questions, time-limited exams, and
                instant results, institute owners can provide an efficient and
                effective learning experience. The platform allows for easy role
                assignment to Professors, Teachers, and Heads of Departments,
                ensuring smooth administration and oversight. Students can sign
                up directly for their specific institution, fostering a
                personalized educational environment. Additional tools like job
                notifications, PDF note uploads, and course videos ensure
                students have comprehensive resources for success, all within a
                user-friendly interface.
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt:3
            }}
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: { xs: 0, md: 5 },
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
                gutterBottom
              >
                Your Ultimate Exam Prep Partner!
              </Typography>
              <p
                style={{
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                }}
              >
                Test Series is designed to enhance the learning experience for
                students by providing a comprehensive and efficient platform for
                exam preparation. With customizable multiple-choice questions
                and time-limited exams, students can practice under real exam
                conditions, improving their time management skills. Instant
                results and top rankers lists offer immediate feedback and
                motivate students to excel. Students also benefit from job
                notifications, keeping them informed about career opportunities,
                and access to notes in PDF format for convenient study.
                Additionally, the platform offers course videos and lectures for
                on-the-go learning, making it easier to grasp complex concepts.
                The user-friendly interface ensures that students can navigate
                and utilize all these features effortlessly, leading to a more
                productive and engaging learning experience.
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6} order={{ xs: 1, md: 2 }}>
            <Box
              component="img"
              src={assets.testseries2}
              alt="IoT pic"
              sx={{
                mt: 10,
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
        </Grid>

        <Box>
          <Card sx={{ backgroundColor: "#f4f4f4", mb: 3, mt:5 }}>
            <CardContent sx={{ backgroundColor: "#f4f4f4" }}>
              <Typography variant="body1" sx={{fontFamily: Fontfamily.poppins,
                  // color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",}}>
                <b>Note:</b> Contact us at testseries@gatiktech.com to request a
                free demo account.
              </Typography>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ pb: 0 }}>
          <Button
            size="small"
            component="a"
            href="https://testseries.gatiktech.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="responsive-button"
            style={{
              textDecoration: "none",
              padding: "1.4rem",
              fontSize: "15px",
              letterSpacing: "2px",
            }}
          >
           Start Your Journey
          </Button>
        </Box>
      </Box>
      <OurClient />
    </Box>
  );
}
