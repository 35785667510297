import React from "react";
import { useParams } from "react-router-dom";
import Testseries from "./Testseries";
import PageTitle from './PageTitle';
import Navbar from "./Navbar";
import Footer from "./Footer";

const ProductDetail = () => {
  const { productId } = useParams();

  const productData = {
    // testseries: <Testseries />,
    testseries: {
      component: <Testseries />,
      type: "Testseries",
    },
  };

  const product = productData[productId];

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <>
    <Navbar />
    <PageTitle title="GatikAI Technologies Test Series | Online Exam Platform for MCQ & Subjective Tests" 
    description="Prepare smarter with GatikAI Test Series! Take MCQ and Subjective exams, access PDF notes, video courses, and get AI-powered performance insights. Ideal for students and competitive exam aspirants."
    url="products/testseries"
    keywords="Test Series, Online Exam Platform, AI-Based Test Analysis, MCQ Exams, Subjective Exams, Competitive Exam Preparation, Online Tests, PDF Notes, Video Courses, Performance Reports, Mock Tests, GatikTech Test Series"
    />   
        {/* {product?.type?.name === "Testseries" ? <Testseries /> : null} */}
        {product.component}
      <Footer />
    </>
  );
};

export default ProductDetail;
