export default {
  // home page images and logo website
  MainImage: require("./images/HomePage.jpg"),
  Mainlogo: require("./images/Logo.png"),
  AiMiImage: require("./images/Home_AI_ML.gif"),
  IoTImage: require("./images/Home_IoT.gif"),
  DevOpsImage: require("./images/Home_DevOps.gif"),
  UXImage: require("./images/Home_UX.gif"),
  CloudImage: require("./images/Home_Cloud.gif"),
  SEOImage: require("./images/seo.gif"),
  letsTalk: require("./images/banner-bg.png"),
  BlogImage: require("./images/homepageblog.png"),

  // industries we Serve images
  Education: require("./images/Education.jpeg"), 
  HealthFitness: require("./images/HealthFitness.jpeg"),
  SocialNetworking: require("./images/SocialNetworking.jpeg"), 
  OTT: require("./images/OTT.jpeg"),
  Travel: require("./images/Travel.jpeg"),
  Transport: require("./images/Transport.jpeg"),
  Ecommerce: require("./images/Ecommerce.jpeg"),
  FoodGrocery: require("./images/FoodGrocery.jpeg"),
 
  // our client images
  abela: require("./images/abela.png"),
  godly: require("./images/godly.png"),
  dealshare: require("./images/dealshare.png"),
  hopster: require("./images/hopster.png"),
  khandelwal: require("./images/khandelwal.png"),
  preeva: require("./images/PreevaFoundationLogo.png"),
  marsplay: require("./images/marsplay.png"),
  govindji: require("./images/govindji.png"),
  FindyourFit: require("./images/FindYourFit.png"),
  idealogic: require("./images/idealogic-logo-min.png"),

  // about us page image
  Director: require("./images/Director.jpg"),
  CIO: require("./images/CIO.jpg"),
  VP: require("./images/VP.jpeg"),
  CTO: require("./images/CTO.jpg"),
  HRHead: require("./images/HRHead.jpeg"),

  // Team
  Team1: require("./images/team1.jpg"),
  Team2: require("./images/team2.jpeg"),
  Team3: require("./images/team3.jpg"),
  Team4: require("./images/team4.png"),


  // MainAboutimg: require("./images/")
  Expertise: require("./images/expertise.png"),
  innovation: require("./images/innovation.png"),
  Quality: require("./images/quality.png"),
  CustomerSupport: require("./images/customerfocus.png"),
  agile: require("./images/agile.png"),
  track: require("./images/track.png"),

  // Work Process
  Approve: require("./images/approve.png"),
  Idea: require("./images/idea.png"),
  Meeting: require("./images/meeting.png"),
  Sketch: require("./images/sketch.png"),
  Launch: require("./images/launch.png"),
  Revision: require("./images/revision.png"),
  WorkProcessBackgroundImage: require("./images/workProcessBackgroundImage.webp"),

  // Services
  Service: require("./images/services.jpg"),
  WebMobile: require("./images/WebMobile.jpg"),
  IoT: require("./images/IOT.jpg"),
  AI_ML: require("./images/AI_ML1.jpg"),
  UX: require("./images/ui_ux.jpg"),
  DevOps: require("./images/devops.jpg"),
  SEO: require("./images/SEO_Service.jpg"),

  career: require("./images/footer-bg.png"),

  // Testimonials 
  TestimonialsImage: require("./images/TestimonialsImage.jpg"),

  // our feature array images
  innovativeSol: require("./images/innovativesolutions.png"),
  experienceandexpertise: require("./images/experienceandexpertise.png"),
  globallocaltouch: require("./images/globalreachlocaltouch.png"),
  securityfocused: require("./images/securityfocused.png"),
  innovativetech: require("./images/innovativetechnology.png"),
  rapidresponse: require("./images/Rapidresponseicon.png"),
  featuresBackgroundImages: require("./images/featuresBackgroundImages.webp"),

  // Product Test Series Image
  testseries1: require("./images/testseries1.png"),
  testseries2: require("./images/testseries2.png"),

  // Blog  page image

  Blogimage: require("./images/man-work.jpg"),
  BlogAuthor: require("./images/BlogAuthor.jpg"),
};
