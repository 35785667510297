import React, { useState } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Box,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import {
  AccountCircle,
  Email,
  Phone,
  ChatBubble,
  PhoneInTalk,
} from "@mui/icons-material";
import ContactImage from "../assets/images/contact.jpg";
import Header from "../Components/Header";
import PageTitle from "../Components/PageTitle";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";
import axios from "../axiosInstance";
import { message } from "antd";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "91",
    phone: "",
    communicationMode: "",
    discussion: "",
  });

  const [loading, setLoading] = useState(false);

  // Handle form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Submit form data
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Form validation
    if (!formData.name) {
      message.error("Please enter your name.");
      setLoading(false);
      return;
    }
    if (!formData.email) {
      message.error("Please enter your email.");
      setLoading(false);
      return;
    }
    if (!formData.phone) {
      message.error("Please enter your phone number.");
      setLoading(false);
      return;
    }
    if (!formData.discussion) {
      message.error("Please enter a discussion message.");
      setLoading(false);
      return;
    }

    try {
      await axios.post("/api/v1/email/send-contact-email", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      message.success(
        "Thanks for contacting us. We'll get back to you as soon as possible!"
      );
      setFormData({
        name: "",
        email: "",
        countryCode: "",
        phone: "",
        communicationMode: "",
        discussion: "",
      });
    } catch (error) {
      message.error("Network error. Please check your connection.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle
        title="Get in Touch | GatikAI Technologies - Web & App Development Services"
        description="Have a project in mind? Contact GatikAI for professional web and mobile app development solutions. Let's build something amazing together!"
        url="contact"
        keywords="web development, app development, GatikAI, software solutions, mobile app, website design"
      />
      <Navbar />
      <Header
        title="Get in Touch With Us"
        description="Have questions or need support? Reach out to us, and our team will get back to you promptly. Let’s collaborate and achieve your goals"
        image={ContactImage}
      />
      <Box
        sx={{
          ml: { xs: 3, md: 10 },
          mr: { xs: 3, md: 10 },
        }}
      >
        <Box my={5}>
          <Typography
            variant="h1"
            sx={{
              letterSpacing: "1.5px",
              fontWeight: FontWeight.bold,
              color: Colors.charcoalgray,
              fontSize: FontSize.thirty,
              fontFamily: Fontfamily.poppins,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
            gutterBottom
          >
            Hate filling out forms?
          </Typography>
          <Grid container spacing={4} mt={-2}>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  color: "#333",
                }}
              >
                Careers
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  mt: 1,
                  color: "#333",
                }}
              >
                careers@gatiktech.com
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  color: "#333",
                }}
              >
                HR queries
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  color: "#333",
                  mt: 1,
                }}
              >
                hr@gatiktech.com
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  color: "#333",
                  mb: 1,
                }}
              >
                Tech queries
              </Typography>
              <Typography
                variant="body2"
                mt={2}
                sx={{
                  mb: 1,
                  fontWeight: 600,
                  fontSize: "1rem",
                  mt: 1,
                  color: "#333",
                }}
              >
                contact@gatiktech.com
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: { xs: 1, md: 10 } }}>
          <Typography
            variant="h4"
            sx={{
              letterSpacing: "1.5px",
              fontWeight: FontWeight.bold,
              color: Colors.charcoalgray,
              fontSize: FontSize.thirty,
              fontFamily: Fontfamily.poppins,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
            }}
            gutterBottom
          >
            Call us
          </Typography>
          <Grid container spacing={4} mt={-2}>
            
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  mb: 1,
                  color: "#333",
                }}
              >
                Careers
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  mb: 1,
                  color: "#333",
                }}
              >
                +9178.9155.5777
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  mb: 1,
                  color: "#333",
                }}
              >
                HR queries
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  mb: 1,
                  color: "#333",
                }}
              >
                {/* +9196.8099.0993 */}
                +9170.1413.3006
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  fontSize: "1.25rem",
                  mb: 1,
                  color: "#333",
                }}
              >
                Tech queries
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 600,
                  fontSize: "1rem",
                  mb: 1,
                  color: "#333",
                }}
              >
                +9196.8099.0993
              </Typography>
            </Grid>

          </Grid>
        </Box>
      </Box>
      <Typography
        variant="h2"
        sx={{
          mt: 10,
          ml: { xs: 3, md: 10 },
          mr: { xs: 3, md: 10 },
          mb: 5,
          letterSpacing: "1.5px",
          fontWeight: FontWeight.bold,
          color: Colors.charcoalgray,
          fontSize: FontSize.thirty,
          fontFamily: Fontfamily.poppins,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
        }}
        gutterBottom
      >
        Consult our specialists for a complimentary estimate
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 8 }}>
        <Paper
          elevation={2}
          sx={{
            p: 4,
            ml: { xs: 3, md: 10 },
            mr: { xs: 3, md: 10 },
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
              mb: 5,
              fontSize: FontSize.eighteen,
              fontWeight: FontWeight.bold,
              fontFamily: Fontfamily.poppins,
              color: Colors.BLACK,
              lineHeight: "1.6",
              letterSpacing: "1px",
            }}
            gutterBottom
          >
            Have a web or mobile app project in mind? Let’s discuss making your
            project a reality.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                placeholder="Enter your full name *"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#f68f5c" } },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                placeholder="Company email *"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#f68f5c" } },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  },
                }}
              />
            </Grid>
            {/* Country Code + Phone Number & Mode of Communication */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Code"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    required
                    type="number"
                    sx={{
                      mt: 2,
                      "& input[type='number']": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          { display: "none" },
                      },
                    }}
                    InputLabelProps={{
                      sx: { "&.Mui-focused": { color: "#f68f5c" } },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={{ color: "#000" }} position="start">
                          +
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    placeholder="Phone number *"
                    variant="outlined"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    type="number"
                    sx={{
                      mt: 2,
                      "& input[type='number']": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          { display: "none" },
                      },
                    }}
                    InputLabelProps={{
                      sx: { "&.Mui-focused": { color: "#f68f5c" } },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone sx={{ color: "#000" }} />
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px",
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                select
                placeholder="Mode of communication *"
                name="communicationMode"
                margin="normal"
                value={formData.communicationMode}
                onChange={handleChange}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#f68f5c" } },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneInTalk sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  },
                }}
              >
                <MenuItem disabled>Mode of communication</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
                <MenuItem value="Phone">Phone</MenuItem>
              </TextField>
            </Grid>
            {/* What would you like to discuss? (Full width) */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                placeholder="What would you like to discuss?"
                variant="outlined"
                multiline
                value={formData.discussion}
                onChange={handleChange}
                rows={3}
                name="discussion"
                sx={{ mb: 2, mt: 2 }}
                InputLabelProps={{
                  sx: { "&.Mui-focused": { color: "#f68f5c" } },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ mb: 5.3 }} position="start">
                      <ChatBubble sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px",
                    },
                  },
                }}
              />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  background:
                    "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)",
                  color: "#fff",
                  mt: 2,
                  py: 1.5,
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "6px",
                  "&:hover": {
                    background:
                      "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#fff" }} />
                ) : (
                  "Inquire now"
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
