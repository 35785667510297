import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { client } from "../Contentful/Client";
import assets from "../assets";
import { FontWeight, Fontfamily, Colors, FontSize } from "../common";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import moment from "moment";

const DateBox = styled(Box)({
  display: "flex",
  background: "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
  color: "#fff",
  padding: "12px 18px",
  borderRadius: "100px",
  marginBottom: "8px",
  marginLeft: "18px",
  width: "auto",
  float: "inline-start",
  position: "absolute",
  bottom: "-30px",
  zIndex: "100",
});

const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({ content_type: "blog" });
        const sortedBlogs = entries.items
          .filter((item) => item?.fields?.blogPostDate)
          .sort(
            (a, b) =>
              new Date(b.fields.blogPostDate) - new Date(a.fields.blogPostDate)
          )
          .slice(0, 3);
        setBlogs(sortedBlogs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25%",
        }}
      >
        <CircularProgress style={{ color: "#f68f5c" }} />
      </p>
    );
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${assets.BlogImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        py: 8,
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          mr: { xs: 3, md: 15 },
          ml: { xs: 3, md: 15 },
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box textAlign="center" my={2} mt={0}>
          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              letterSpacing: "1.5px",
              fontWeight: FontWeight.bold,
              color: Colors.WHITE,
              fontSize: FontSize.thirty,
              fontFamily: Fontfamily.poppins,
              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
              // zIndex: 2,
              // position: "relative",
            }}
            gutterBottom
          >
            Tech & Business Insights
          </Typography>

          <Typography
            paragraph
            sx={{
              ml: 2,
              mr: 2,
              textAlign: "center",
              color: Colors.WHITE,
              fontFamily: Fontfamily.poppins,
              fontSize: FontSize.fifteen,
              letterSpacing: "1.5px",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
              zIndex: 2,
              position: "relative",
            }}
          >
          Stay updated with the latest IT trends, expert insights, and practical tech guides on our blog

          </Typography>
        </Box>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {blogs.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} mb={5}>
              <Card
                sx={{
                  borderRadius: "10px",
                  boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                  },
                  height: "450px",
                }}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={item?.fields?.blogImage?.fields?.file?.url}
                  alt={item?.fields?.blogTitle}
                />
                <Box style={{ position: "relative", zIndex: 2 }}>
                  <DateBox>
                    <CalendarTodayIcon fontSize="small" />
                    <Typography variant="body2" ml={1}>
                      {moment(item?.fields?.blogPostDate).format(
                        "MMMM DD, YYYY"
                      )}
                    </Typography>
                  </DateBox>
                </Box>
                <CardContent sx={{ mt: 2 }}>
                  <Typography
                    variant="h6"
                    component="h3"
                    sx={{
                      mb: 2,
                      fontWeight: "bold",
                      letterSpacing: "1.5px",
                      fontFamily: Fontfamily.poppins,
                      fontSize: FontSize.eighteen,
                      color: "rgba(9, 8, 8, 0.68)",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.fields?.blogTitle}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      mb: 2,
                      mt: 2,
                      letterSpacing: "1.5px",
                      fontFamily: Fontfamily.poppins,
                      fontSize: FontSize.fourteen,
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item?.fields?.blogDisplayTitle}
                  </Typography>
                  <Link to={`/blogs`} style={{ textDecoration: "none" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: 2,
                        color: "#f68f5c",
                       
                        fontWeight: FontWeight.bold,
                        "&:hover": { color: "#FF716E" }, 

                      }}
                    >
                      <Typography variant="button" sx={{ fontSize: FontSize.twelve,}}>
                        Read our latest blogs
                      </Typography>
                      <ArrowForwardIcon sx={{ ml: 1 }} />
                    </Box>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Blog;
