import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";

const Testimonial = () => {
  const ratings = [
    "Skills",
    "Quality of work",
    "Availability",
    "Adherence to Schedule",
    "Communication",
    "Cooperation",
  ];

  const stars = [5, 5, 5, 5, 5, 5];
  return (
    <Box
      sx={{
        background: "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)",
        color: "white",
        p: 4,
      }}
    >
      {/* Header Section */}
      <Box textAlign="center" mb={3}>
        <Typography variant="h4" fontWeight="bold">
          Testimonials
        </Typography>
        <Typography variant="body1" mt={1} mx="auto">
          A company profile is a formal introduction of your business. It
          informs others about the company’s products and services. It’s an
          effective way to introduce your company to your customers, creditors,
          and other stakeholders.
        </Typography>
      </Box>
      <Card
        sx={{
          ml: { md: 10, xs: 0 },
          mr: { md: 10, xs: 0 },
          p: 2,
          borderRadius: 3,
          boxShadow: 3,
          mt: 5,
          mb: 5,
          transition: "transform 0.3s ease",
          "&:hover": { transform: "scale(1.05)" },
        }}
      >
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            {/* Left Side - Avatar & Company */}
            <Grid item xs={12} md={8} display="flex" alignItems="center">
              <Grid item xs={12} md={8}>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ width: 60, height: 60, mr: 2 }} />
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      fontFamily: Fontfamily.poppins,
                      fontSize: FontSize.seventeen,
                      letterSpacing: "1.2px",
                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    Navv Systems 
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontWeight: "normal",
                    fontFamily: Fontfamily.poppins,
                    letterSpacing: "1.5px",
                  }}
                  mt={2}
                >
                  Project Name:{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1.5px",
                    }}
                  >
                    NAVV 
                  </span>
                </Typography>
                <Typography
                  variant="subtitle1"
                  mt={1}
                  sx={{
                    fontWeight:"bold",
                    fontFamily: Fontfamily.poppins,
                    letterSpacing: "1.5px",
                  }}
                >
                  Suggestions/Comments:
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{
                    fontFamily: Fontfamily.poppins,
                    color: Colors.MediumGray,
                    lineHeight: "1.6",
                    letterSpacing: "1px",
                  }}
                >
                  GATIKAI has been a reliable and forward-thinking partner in the NavvSystem project.Collaborating with them on the NavvSystem project has been a highly rewarding experience. Their team exhibited exceptional technical acumen, strategic problem-solving capabilities, and a strong commitment to delivering excellence. Their proactive approach and meticulous attention to detail played a pivotal role in the project's success. We highly appreciate their expertise and look forward to future collaborations
                </Typography>
              </Grid>
            </Grid>

            {/* Right Side - Ratings */}
            <Grid item xs={12} md={4}>
              {ratings.map((label, index) => (
                <Box
                  key={index}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      fontFamily: Fontfamily.poppins,
                      color: Colors.MediumGray,
                      lineHeight: "1.6",
                      letterSpacing: "1px",
                      fontSize: FontSize.fifteen,
                    }}
                  >
                    {label}:
                  </Typography>
                  <Box>
                    {[...Array(5)].map((_, i) => (
                      <StarIcon
                        key={i}
                        sx={{ color: i < stars[index] ? "#FFA500" : "#ddd" }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Grid>

            <Link to={`/testimonials`} style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  ml: 3,
                  display: "flex",
                  alignItems: "center",
                  mt: 2,
                  color: "#f68f5c",
                  fontWeight: FontWeight.bold,
                  "&:hover": { color: "#FF716E" },
                }}
              >
                <Typography variant="button">Go to Testimonials</Typography>
                <ArrowForwardIcon sx={{ ml: 1 }} />
              </Box>
            </Link>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Testimonial;
