import React from "react";
import { Box, Typography, Card, CardMedia } from "@mui/material";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import assets from "../assets";
import { FontWeight, Colors, Fontfamily, FontSize } from "../common";

const logos = [
  { id: "1", image: assets.abela },
  { id: "2", image: assets.godly },
  { id: "3", image: assets.dealshare },
  { id: "4", image: assets.hopster },
  { id: "5", image: assets.khandelwal },
  { id: "6", image: assets.preeva },
  { id: "7", image: assets.marsplay },
  { id: "8", image: assets.govindji },
  { id: "10", image: assets.idealogic },
  { id: "9", image: assets.FindyourFit },
];

const OurClient = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ mt: 8, mb: 8, ml: { xs: 1, md: 10 }, mr: { xs: 1, md: 10 } }}>
      <Box className="heading-box">
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
          gutterBottom
        >
          Brands we have worked with
        </Typography>

        <Typography
          paragraph
          sx={{
            ml: 2,
            mr: 2,
            textAlign: "center",
            color: Colors.MediumGray,
            fontFamily: Fontfamily.poppins,
            letterSpacing: "1.5px",
            fontSize: FontSize.fifteen,
            textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
          }}
        >
          From humble beginnings with local brands, GatikAI's dedication and
          expertise have earned us the trust of top brands, enabling us to
          deliver solutions that cater to their business requirements.
        </Typography>
      </Box>

      {/* Mobile view */}
      <Box sx={{ display: { xs: "block", md: "none" }, p: 5, mt: 5 }}>
        <Slider {...settings}>
          {logos.map((logo) => (
            <Box key={logo.id} sx={{ textAlign: "center" }}>
              <Box
                component="img"
                src={logo.image}
                alt={`Logo ${logo.id}`}
                sx={{
                  height: "150px",
                  width: "180px",
                  padding: "50px auto",
                  cursor: "pointer",
                  transition: "filter 0.3s ease",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "0 auto",
                }}
                loading="lazy"
              />
            </Box>
          ))}
        </Slider>
      </Box>

      {/* Desktop view */}
      <Grid
        container
        spacing={4}
        rowSpacing={1}
        columnSpacing={5}
        sx={{
          display: { xs: "none", md: "flex" },
          p: 5,
        }}
      >
        {logos.map((logo) => (
          <Grid
            item
            xs={12}
            sm={4}
            md={2.4}
            key={logo.id}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Card
              sx={{
                mt: 5,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                maxWidth: 345,
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                transition: "transform 0.3s ease, filter 0.3s ease",
                "&:hover": {
                  transform: "scale(1.05)",
                  filter: "grayscale(0%)",
                },
              }}
            >
              <CardMedia
                component="img"
                image={logo.image}
                alt={`Logo ${logo.id}`}
                sx={{
                  height: "140px",
                  objectFit: "contain",
                  padding: "16px",
                  cursor: "pointer",
                  transition: "filter 0.3s ease",
                }}
                loading="lazy"
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OurClient;
