import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AboutImage from "../assets/images/AboutUsImage.jpg";
import CardContent from "@mui/material/CardContent";
import assets from "../assets";
import Header from "../Components/Header";
import { Colors, FontSize, FontWeight, Fontfamily } from "../common";
import OurClient from "../Components/OurClient";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Testimonial from "../Components/Testimonial";
import PageTitle from "../Components/PageTitle";

const About = () => {
  const isMediumScreen = useMediaQuery(
    "(min-width: 900px) and (max-width: 1400px)"
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const leaders = [
    {
      name: "Priyanka Sharma",
      title: "Director",
      description:
        "Priyanka founded GatikAI Technologies to bridge technology and user-friendly solutions, driving business growth with scalable innovations.",
      image: assets.Director,
    },
    {
      name: "Abhyuday Mathur",
      title: "Co-founder",
      description:
        "Abhyuday brings 10 years of expertise in Customer Success and SaaS, shaping GatikAI's mission and strategic vision.",
      image: assets.CIO,
    },
    {
      name: "Sandeep Sharma",
      title: "VP of Delivery",
      description:
        "Sandeep ensures seamless project execution and client satisfaction, driving efficiency and excellence in IT delivery.",
      image: assets.VP,
    },
    {
      name: "Dinesh Saini",
      title: "CTO",
      description:
        "Dinesh leads technology strategy and innovation, leveraging cloud computing and agile methodologies for scalable solutions.",
      image: assets.CTO,
    },
    {
      name: "Amit Sharma",
      title: "HR Head",
      description:
        "Keeping our team happy and thriving, Amit Sharma is our HR Head and the heart of GatikAI Technologies, with a Master’s in Human Resources and a commitment to change.",
      image: assets.HRHead,
    },
  ];

  // const team = [
  //   {
  //     name: "Sanjeev Kumar",
  //     title: "Full Stack Developer",
  //     description:
  //     "Sanjeev Kumar is a Full Stack Developer at GatikAI Technologies, specializing in frontend & backend development, database management, and AWS infrastructure. He ensures scalable, secure, and high-performance applications.",
  //     image: assets.Team1,
  //   },

  //   {
  //     name: "Bhavna Sharma",
  //     title: "React Native Developer",
  //     description:
  //     "Sanjeev Kumar is a Full Stack Developer at GatikAI Technologies, specializing in frontend & backend development, database management, and AWS infrastructure. He ensures scalable, secure, and high-performance applications.",

  //       image: assets.Team2,
  //   },
  //   {
  //     name: "Abhishek Sharma",
  //     title: "React Native Developer",
  //     description:
  //       "Sandeep ensures seamless project execution and client satisfaction, driving efficiency and excellence in IT delivery.",
  //     image: assets.Team3,
  //   },

  //   {
  //     name: "Shobhit Sharma",
  //     title: "Data Analytics",
  //     description:
  //       "Dinesh leads technology strategy and innovation, leveraging cloud computing and agile methodologies for scalable solutions.",
  //     image: assets.Team4,
  //   },
  // ];

  return (
    <>
      <PageTitle
        title="About GatikAI Technologies | Innovation, Leadership & IT Solutions"
        description="Explore GatikAI, a leader in AI-driven IT solutions. Learn about our mission, leadership team, and commitment to innovation in software development, cloud computing, and AI solutions."
        url="about"
        keywords="About GatikTech, GatikAI Technologies, GatikTech Technologies, IT Leadership, AI Solutions, Cloud Computing, Software Development, Innovation, Digital Transformation, Technology Partner, IT Consulting"
      />
      <Navbar />
      <Header
        title="About Us"
        description="At GatikAI Technologies, we bring innovation and excellence to IT services. Since 2021, we’ve been a trusted partner, offering tailored digital solutions for diverse business needs"
        image={AboutImage}
      />
      <Box sx={{ mt: 5, ml: { xs: 0, md: 10 }, mr: { xs: 0, md: 10 }, mb: 8 }}>
        <Typography
          variant="h1"
          sx={{
            ml: 3.5,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
          gutterBottom
        >
          Director Message
        </Typography>
        <Box sx={{ p: { xs: 3, md: 3 }, borderRadius: 2 }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={4}>
              <img
                src={assets.Director}
                alt="Priyanka Sharma - Director of GatikAI Technologies"
                style={{
                  borderRadius: "10px",
                  objectFit: "cover",
                  objectPosition: "center",
                  height: isMediumScreen ? "600px" : "auto",
                  width: isMediumScreen ? "100%" : "100%",
                }}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <CardContent>
                <Typography
                  variant="h2"
                  sx={{
                    letterSpacing: "1.5px",
                    fontWeight: FontWeight.bold,
                    color: Colors.charcoalgray,
                    fontSize: FontSize.thirty,
                    fontFamily: Fontfamily.poppins,
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                  }}
                  gutterBottom
                >
                  Priyanka Sharma
                </Typography>
                <Box
                  sx={{
                    ml: { xs: 0, md: 0 },
                    mr: { xs: 0, md: 0 },
                  }}
                >
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{
                      mt: 4,
                      mb: 2,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1.3px",
                      fontSize: FontSize.seventeen,
                    }}
                  >
                    A very warm welcome to GatikAI Technologies. It is my pleasure to introduce you to this company which has grown tremendously since its inception in 2021, and is slowly but surely evolving in stature.  GatikAI Technologies has been committed to delivering cutting-edge IT solutions, including AI, machine learning, cloud computing, website/ mobile apps development and enterprise software development. Our expertise spans multiple industries, helping businesses automate processes, enhance efficiency, and drive digital transformation
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{
                      mb: 2,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1.3px",
                      fontSize: FontSize.seventeen,
                    }}
                  >
                  With a strong focus on innovation, excellence, and customer satisfaction, our dedicated team develops intelligent, scalable solutions that empower businesses in an evolving digital landscape. As a trusted technology partner, we strive to create meaningful impact and unlock new opportunities for our clients.
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{
                      mb: 2,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1.3px",
                      fontSize: FontSize.seventeen,
                    }}
                  >
                    Thank you for being a part of our journey. We look forward
                    to collaborating and achieving new milestones together.
                  </Typography>
                </Box>
              </CardContent>
            </Grid>
          </Grid>
        </Box>

        <Typography
          variant="h2"
          sx={{
            mt: { xs: 0, md: 10 },
            ml: 3,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
          gutterBottom
        >
          Why choose us?
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            mt: 3,
            ml: { xs: 3, md: 3 },
            mr: { xs: 3, md: 3 },
            color: "#666",
            lineHeight: "1.5",
            letterSpacing: 1.5,
          }}
        >
          At GatikAI Technologies, we provide cutting-edge IT solutions designed
          for security, scalability, and innovation. With a team of experienced
          professionals, we specialise in software development, cloud computing,
          cybersecurity, and IT consulting, ensuring your business stays ahead
          in the digital landscape. Our business-centric approach aligns
          technology with your goals, delivering measurable growth and
          efficiency. Backed by a proven track record and a commitment to
          excellence, we offer 24/7 support and enterprise-grade reliability, so
          your operations run smoothly without disruptions. Choose us for
          expert-driven, future-ready IT solutions tailored to your success.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            mt: { xs: 0, md: 10 },
            ml: 3,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
          gutterBottom
        >
          Meet our leadership
        </Typography>

        <Box id="portfolio" sx={{ ml: { xs: 2, md: 3 }, mr: { xs: 2, md: 3 } }}>
          <div className="container">
            <div className="work-list">
              {leaders.map((leader, index) => (
                <div className="work" key={index}>
                  <img
                    src={leader.image}
                    alt={leader.name}
                    style={{ height: "43vh", borderRadius: "10px" }}
                  />
                  <div className="work-title">
                    <h3>{leader.name}</h3>
                    <span
                      style={{
                        marginLeft: "12px",
                        marginRight: "12px",
                        fontWeight: FontWeight.bold,
                        fontSize: "14px",
                        lineHeight: 1.8,
                      }}
                    >
                      {leader.title}
                    </span>
                    <p>{leader.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>

        {/* <Typography
          variant="h2"
          sx={{
            mt: { xs: 0, md: 10 },
            ml: 3,
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
          gutterBottom
        >
          Meet the Innovators: Our Development Team
          Our Tech Powerhouse
          Meet the Minds Behind the Code
        </Typography>

        <Box id="portfolio" sx={{ ml: { xs: 2, md: 3 }, mr: { xs: 2, md: 3 } }}>
          <div className="container">
            <div className="work-list">
              {team.map((leader, index) => (
                <div className="work" key={index}>
                  <img
                    src={leader.image}
                    alt={leader.name}
                    style={{ height: "43vh", borderRadius: "10px" }}
                  />
                  <div className="work-title">
                    <h3>{leader.name}</h3>
                    <span
                      style={{
                        marginLeft: "12px",
                        marginRight: "12px",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: 1.8,
                      }}
                    >
                      {leader.title}
                    </span>
                    <p>{leader.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box> */}
      </Box>

      <Testimonial />

      <OurClient />

      <Footer />
    </>
  );
};

export default About;
