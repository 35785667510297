import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Career from './pages/Career';
import PrivacyandPolicy from './pages/PrivacyandPolicy';
import TermsandConditions from './pages/TermsandConditions';
import ProductDetail from "./Components/ProductDetail";
import NotFoundPage from "./pages/NotFoundPage";
import BlogPost from './Components/BlogPost';
import SingleBlogPost from './pages/SingleBlogPost';
import GooglePrivacyAndPolicy from './pages/GooglePrivacyAndPolicy'
import GatikApps from "./pages/GatikApps";
import Testimonials from "./pages/Testimonials";

const App = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path ="/blogs" element={<BlogPost />} />
          {/* <Route path="/blogs/:title:id" element={<SingleBlogPost />} /> */}
          <Route path="/blogs/:slug" element={<SingleBlogPost />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/career" element={<Career />} />
          <Route path="/products/:productId" element={<ProductDetail/>} />
          <Route path="/privacy-policy" element={<GooglePrivacyAndPolicy />} />
          <Route path="/termsandconditions" element={<TermsandConditions />} />
          <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
          <Route path="/download-apps" element={<GatikApps />} />
        <Route path="*" element={<NotFoundPage />} />
        </Routes>
        {/* <Footer /> */}
      </BrowserRouter>
    </>
  );
};

export default App;
