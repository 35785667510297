// import React from "react";
// import {
//   Box,
//   Typography,
//   Container,
//   Grid,
//   Card,
//   CardContent,
// } from "@mui/material";
// import "../stylesheets/Workprocess.css";
// import { Colors, FontSize, Fontfamily, FontWeight } from "../common";
// import assets from "../assets";

// const WorkProcessCards = [
//   {
//     imageSrc: assets.Idea,
//     Workprocesstitle: "Get Ideas",
//     Workprocessdescription: "Create your own style As need.",
//   },
//   {
//     imageSrc: assets.Sketch,
//     Workprocesstitle: "Sketch Up",
//     Workprocessdescription: "Design is thinking Made visual.",
//   },
//   {
//     imageSrc: assets.Meeting,
//     Workprocesstitle: "Discuss",
//     Workprocessdescription: "Together we can transform Design.",
//   },
//   {
//     imageSrc: assets.Revision,
//     Workprocesstitle: "Revise",
//     Workprocessdescription: "Identify problems and give solutions.",
//   },
//   {
//     imageSrc: assets.Approve,
//     Workprocesstitle: "Approve",
//     Workprocessdescription: "Completed Demonstrate and decide.",
//   },
//   {
//     imageSrc: assets.Launch,
//     Workprocesstitle: "Launch",
//     Workprocessdescription: "The Way to get started To Express",
//   },
// ];

// const WorkProcess = () => {
//   return (
//     <Box
//       component="section"
//       aria-labelledby="workprocess-heading"
//       className="workprocess-heading"
//       sx={{
//         background: "#F68F5C",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//         pb: 5,
//         pt: 5,
//         mt: 5,
//       }}
//     >
//       <Typography
//         id="workprocess-heading"
//         variant="h2"
//         sx={{
//           textAlign: "center",
//           letterSpacing: "1.5px",
//           fontWeight: FontWeight.bold,
//           fontSize: FontSize.twNine,
//           color: Colors.WHITE,
//           fontFamily: Fontfamily.poppins,
//         }}
//       >
//         Work Process
//       </Typography>
//       <Typography
//         variant="p"
//         sx={{
//           color: Colors.WHITE,
//           textAlign: "center",
//           letterSpacing: "1.5px",
//           pl: 1,
//           pr: 1,
//           lineHeight: "26px",
//           fontWeight: FontWeight.heading4,
//           fontSize: FontSize.fifteen,
//           fontFamily: Fontfamily.poppins,
//           mt: 1,
//         }}
//       >
//         We have continued to adapt ourselves to the changes within the digital
//         industry and refined our processes to produce awesome web and mobile
//         work for our clients.
//       </Typography>

//       <Container maxWidth="lg" sx={{ pt: 3 }}>
//         <Grid
//           container
//           spacing={4}
//           rowSpacing={3}
//           columnSpacing={4}
//           justifyContent="center"
//           alignItems="center"
//         >
//           {WorkProcessCards.map((card, index) => (
//             <Grid
//               item
//               key={index}
//               component="article"
//               aria-labelledby={`workprocess-card-${index}`}
//             >
//               <Card
//                 className="WorkProcess-card"
//                 sx={{
//                   background: "#fff",
//                   borderRadius: "20px",
//                   boxShadow: "10px 10px #F19D72",
//                   textAlign: "center",
//                   mt: 4,
//                   mb: 4,
//                   display: "flex",
//                   justifyContent: "center",
//                   transition: "all 0.5s ease",
//                   height: "195px",
//                   width: "165px",
//                 }}
//               >
//                 <CardContent>
//                   <img
//                     alt={`${card.Workprocesstitle} Icon`}
//                     src={card.imageSrc}
//                     width="40"
//                     height="40"
//                     loading="lazy" // Improves page load speed
//                   />
//                   <Typography
//                     id={`workprocess-card-${index}`}
//                     variant="h3"
//                     sx={{
//                       mt: 2,
//                       fontWeight: FontWeight.heading4,
//                       color: Colors.Darkshadeofgrey,
//                       display: "block",
//                       fontSize: FontSize.seventeen,
//                       letterSpacing: "1.5px",
//                       mb: 2,
//                     }}
//                   >
//                     {card.Workprocesstitle}
//                   </Typography>
//                   <Typography
//                     variant="body1"
//                     sx={{
//                       mt: 1,
//                       color: Colors.DimGray,
//                       display: "block",
//                       fontSize: FontSize.fourteen,
//                       fontWeight: FontWeight.heading5,
//                       fontFamily: Fontfamily.poppins,
//                       letterSpacing: "1px",
//                     }}
//                   >
//                     {card.Workprocessdescription}
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Container>
//     </Box>
//   );
// };

// export default WorkProcess;

import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import "../stylesheets/Workprocess.css";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";
import assets from "../assets";

const WorkProcessCards = [
  {
    imageSrc: assets.Idea,
    Workprocesstitle: "Get Ideas",
    Workprocessdescription: "Create your own style as needed.",
  },
  {
    imageSrc: assets.Sketch,
    Workprocesstitle: "Sketch Up",
    Workprocessdescription: "Design is thinking made visual.",
  },
  {
    imageSrc: assets.Meeting,
    Workprocesstitle: "Discuss",
    Workprocessdescription: "Together we can transform design.",
  },
  {
    imageSrc: assets.Revision,
    Workprocesstitle: "Revise",
    Workprocessdescription: "Identify problems and give solutions.",
  },
  {
    imageSrc: assets.Approve,
    Workprocesstitle: "Approve",
    Workprocessdescription: "Completed, demonstrate and decide.",
  },
  {
    imageSrc: assets.Launch,
    Workprocesstitle: "Launch",
    Workprocessdescription: "The way to get started is to express.",
  },
];

const WorkProcess = () => {
  return (
    // <Box
    //   component="section"
    //   aria-labelledby="workprocess-heading"
    //   className="workprocess-heading"
    //   sx={{
    //     background: "linear-gradient(135deg, #FF7F50, #FF4500)",
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     pb: 8,
    //     pt: 8,
    //     mt: 5,
    //     color: Colors.WHITE,
    //   }}
    // >
    //   <Typography
    //     id="workprocess-heading"
    //     variant="h2"
    //     sx={{
    //       textAlign: "center",
    //       fontWeight: FontWeight.bold,
    //       fontSize: FontSize.twNine,
    //       color: Colors.WHITE,
    //       fontFamily: Fontfamily.poppins,
    //       letterSpacing: "2px",
    //     }}
    //   >
    //     Work Process
    //   </Typography>
    //   <Typography
    //     variant="body1"
    //     sx={{
    //       color: Colors.WHITE,
    //       textAlign: "center",
    //       letterSpacing: "1.5px",
    //       maxWidth: "700px",
    //       fontSize: FontSize.fifteen,
    //       lineHeight: "26px",
    //       mt: 2,
    //     }}
    //   >
    //     Adapting to the ever-changing digital landscape, we refine our processes to deliver exceptional web and mobile experiences.
    //   </Typography>

    //   <Container maxWidth="lg" sx={{ pt: 5 }}>
    //     <Grid
    //       container
    //       spacing={4}
    //       justifyContent="center"
    //       alignItems="center"
    //     >
    //       {WorkProcessCards.map((card, index) => (
    //         <Grid item xs={12} sm={6} md={4} key={index}>
    //           <motion.div
    //             whileHover={{ scale: 1.05 }}
    //             whileTap={{ scale: 0.95 }}
    //             transition={{ duration: 0.3 }}
    //           >
    //             <Card
    //               sx={{
    //                 background: "#fff",
    //                 borderRadius: "15px",
    //                 boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
    //                 textAlign: "center",
    //                 p: 3,
    //                 overflow: "hidden",
    //               }}
    //             >
    //               <Box
    //                 sx={{
    //                   height: "80px",
    //                   width: "80px",
    //                   background: "linear-gradient(135deg, #FF7F50, #FF4500)",
    //                   borderRadius: "50%",
    //                   display: "flex",
    //                   justifyContent: "center",
    //                   alignItems: "center",
    //                   margin: "0 auto",
    //                   mb: 2,
    //                 }}
    //               >
    //                 <img
    //                   alt={`${card.Workprocesstitle} Icon`}
    //                   src={card.imageSrc}
    //                   style={{ height: "40px", width: "40px" }}
    //                 />
    //               </Box>
    //               <Typography
    //                 variant="h5"
    //                 sx={{
    //                   fontWeight: FontWeight.bold,
    //                   fontSize: FontSize.seventeen,
    //                   color: Colors.Darkshadeofgrey,
    //                   mb: 1,
    //                 }}
    //               >
    //                 {card.Workprocesstitle}
    //               </Typography>
    //               <Typography
    //                 variant="body2"
    //                 sx={{
    //                   color: Colors.DimGray,
    //                   fontSize: FontSize.fourteen,
    //                   fontWeight: FontWeight.regular,
    //                   lineHeight: "22px",
    //                 }}
    //               >
    //                 {card.Workprocessdescription}
    //               </Typography>
    //             </Card>
    //           </motion.div>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Container>
    // </Box>

    <Box
      component="section"
      aria-labelledby="workprocess-heading"
      className="workprocess-heading"
      sx={{
        backgroundImage: `url(${assets.WorkProcessBackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pb: 5,
        pt: 5,
        mt: 5,
        position: "relative",
      }}
    >
      {/* Overlay for readability */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      {/* Content */}
      {/* <Typography
        id="workprocess-heading"
        variant="h2"
        sx={{
          textAlign: "center",
          letterSpacing: "1.5px",
          fontWeight: FontWeight.bold,
          fontSize: FontSize.twNine,
          color: Colors.WHITE,
          fontFamily: Fontfamily.poppins,
          position: "relative",
          zIndex: 2,
        }}
      >
        Work Process
      </Typography>
      <Typography
        variant="p"
        sx={{
          color: Colors.WHITE,
          textAlign: "center",
          letterSpacing: "1.5px",
          pl: 1,
          pr: 1,
          lineHeight: "26px",
          fontWeight: FontWeight.heading4,
          fontSize: FontSize.fifteen,
          fontFamily: Fontfamily.poppins,
          mt: 1,
          position: "relative",
          zIndex: 2,
        }}
      >
        We have continued to adapt ourselves to the changes within the digital
        industry and refined our processes to produce awesome web and mobile
        work for our clients.
      </Typography> */}

      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          letterSpacing: "1.5px",
          fontWeight: FontWeight.bold,
          color: Colors.WHITE,
          fontSize: FontSize.thirty,
          fontFamily: Fontfamily.poppins,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          zIndex: 2,
          position: "relative",
        }}
        gutterBottom
      >
        Work Process
      </Typography>

      <Typography
        paragraph
        sx={{
          ml: 2,
          mr: 2,
          textAlign: "center",
          color: Colors.WHITE,
          fontFamily: Fontfamily.poppins,
          letterSpacing: "1.5px",
          fontSize: FontSize.fifteen,
          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
          zIndex: 2,
          position: "relative",
        }}
      >
        We have continued to adapt ourselves to the changes within the digital
        industry and refined our processes to produce awesome web and mobile
        work for our clients.
      </Typography>
      {/* Cards Section */}
      <Container maxWidth="lg" sx={{ pt: 3, position: "relative", zIndex: 2 }}>
        <Grid
          container
          spacing={4}
          rowSpacing={3}
          columnSpacing={4}
          justifyContent="center"
          alignItems="center"
        >
          {WorkProcessCards.map((card, index) => (
            <Grid
              item
              key={index}
              component="article"
              aria-labelledby={`workprocess-card-${index}`}
            >
              <Card
                className="WorkProcess-card"
                sx={{
                  background: "#fff",
                  borderRadius: "20px",
                  boxShadow: "10px 10px #F19D72",
                  textAlign: "center",
                  mt: 4,
                  mb: 4,
                  display: "flex",
                  justifyContent: "center",
                  transition: "all 0.5s ease",
                  height: "195px",
                  width: "165px",
                }}
              >
                <CardContent>
                  <img
                    alt={`${card.Workprocesstitle} Icon`}
                    src={card.imageSrc}
                    width="40"
                    height="40"
                    loading="lazy" // Improves page load speed
                  />
                  <Typography
                    id={`workprocess-card-${index}`}
                    variant="h3"
                    sx={{
                      mt: 2,
                      fontWeight: FontWeight.heading4,
                      color: Colors.Darkshadeofgrey,
                      display: "block",
                      fontSize: FontSize.seventeen,
                      letterSpacing: "1.5px",
                      mb: 2,
                    }}
                  >
                    {card.Workprocesstitle}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: 1,
                      color: Colors.DimGray,
                      display: "block",
                      fontSize: FontSize.fourteen,
                      fontWeight: FontWeight.heading5,
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1px",
                    }}
                  >
                    {card.Workprocessdescription}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WorkProcess;
