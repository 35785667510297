// import React, { useState, useEffect, useRef } from "react";
// import { Box, Grid, Typography, Button } from "@mui/material";
// import { Colors, Fontfamily } from "../common";
// import { Link } from "react-router-dom";

// const Header = (props) => {
//   const { title, description, image, placeholder, link } = props;
//   const [isLoaded, setIsLoaded] = useState(false);
//   const imgRef = useRef(null);

//   useEffect(() => {
//     const observer = new IntersectionObserver(
//       (entries, observer) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             setIsLoaded(true);
//             observer.disconnect();
//           }
//         });
//       },
//       { threshold: 0.1 }
//     );

//     if (imgRef.current) {
//       observer.observe(imgRef.current);
//     }

//     return () => {
//       if (imgRef.current) {
//         observer.unobserve(imgRef.current);
//       }
//     };
//   }, []);

//   return (
//     <>
//       <Box
//         sx={{
//           backgroundImage: isLoaded ? `url(${image})` : `url(${placeholder})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center",
//           backgroundAttachment: { xs: "scroll", md: "fixed" },
//           height: { xs: "980px", md: "980px" },
//           position: "relative",
//         }}
//       >
//         <Box
//           sx={{
//             width: "100%",
//             height: "100%",
//             position: "absolute",
//             backgroundColor: "rgba(0, 0, 0, 0.4)",
//           }}
//         />
//         <Grid
//           container
//           sx={{
//             pt: { xs: 30, md: 45 },
//             px: { xs: 2, md: 5 },
//             color: "#fff",
//             opacity: 1,
//             position: "relative",
//           }}
//         >
//           <Typography
//             sx={{
//               width: "100%",
//               textAlign: "center",
//               fontWeight: "900",
//               fontSize: { xs: "40px", sm: "40px", md: "80px" },
//               fontFamily: Fontfamily.playfairDisplay,
//               letterSpacing: "3.8px",
//             }}
//           >
//             {title}
//           </Typography>
//           <Typography
//             sx={{
//               fontSize: { xs: "17px", sm: "18px", md: "22px", lg: "25px" },
//               lineHeight: "2.0",
//               textAlign: "center",
//               fontWeight: "bold",
//               letterSpacing: { xs: "1.5px", md: "2.5px" },
//               pt: 2,
//               m: { xs: 0, md: -1 },
//               fontFamily: Fontfamily.playfairDisplay,
//             }}
//           >
//             {description}
//           </Typography>

//           <Grid
//             container
//             sx={{
//               width: "100%",
//               height: "100%",
//               position: "relative",
//               justifyContent: "center",
//               alignItems: "center",
//               mt: 5,
//             }}
//           >
//             {link?.length > 0 && (
//               <Button
//                 variant="outlined"
//                 sx={{
//                   border: "1px solid #f68f5c",
//                   color: Colors.WHITE,
//                   borderRadius: "10px",
//                   backgroundColor: "#f68f5c",
//                   "&:hover": {
//                     backgroundColor: "#fd7e14",
//                     color: Colors.WHITE,
//                     border: "1px solid #f68f5c",
//                   },
//                 }}
//               >
//                 <Link
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   to={link}
//                   style={{
//                     color: "inherit",
//                     textDecoration: "none",
//                     letterSpacing: "2px",
//                   }}
//                 >
//                   Join the Test Series
//                 </Link>
//               </Button>
//             )}
//           </Grid>
//         </Grid>
//         <img
//           ref={imgRef}
//           src={image}
//           alt="background"
//           style={{ display: "none" }}
//           onLoad={() => setIsLoaded(true)}
//         />
//       </Box>
//     </>
//   );
// };

// export default Header;


// import React, { useState, useEffect, useRef } from "react";
// import { Box, Grid, Typography, Button } from "@mui/material";
// import { Colors, Fontfamily } from "../common";
// import { Link } from "react-router-dom";

// const Header = ({ title, description, image, placeholder, link }) => {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const imgRef = useRef(null);

//   useEffect(() => {
//     if ("IntersectionObserver" in window) {
//       const observer = new IntersectionObserver(
//         ([entry]) => {
//           if (entry.isIntersecting) {
//             setIsLoaded(true);
//             observer.disconnect();
//           }
//         },
//         { threshold: 0.1 }
//       );

//       if (imgRef.current) observer.observe(imgRef.current);

//       return () => observer.disconnect();
//     } else {
//       setIsLoaded(true);
//     }
//   }, []);

//   return (
//     <Box
//       sx={{
//         backgroundImage: `url(${isLoaded ? image : placeholder})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundAttachment: { xs: "scroll", md: "fixed" },
//         height: { xs: "90vh", md: "100vh" },
//         position: "relative",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       {/* Overlay */}
//       <Box
//         sx={{
//           width: "100%",
//           height: "100%",
//           position: "absolute",
//           backgroundColor: "rgba(0, 0, 0, 0.4)",
//         }}
//       />

//       {/* Content */}
//       <Grid
//         container
//         direction="column"
//         alignItems="center"
//         textAlign="center"
//         sx={{
//           position: "relative",
//           color: "#fff",
//           px: { xs: 3, md: 5 },
//         }}
//       >
//         {/* Title */}
//         <Typography
//           variant="h1"
//           sx={{
//             fontWeight: "900",
//             fontSize: { xs: "32px", sm: "48px", md: "64px" },
//             fontFamily: Fontfamily.playfairDisplay,
//             letterSpacing: "2px",
//           }}
//         >
//           {title}
//         </Typography>

//         {/* Subtext */}
//         <Typography
//           variant="h6"
//           sx={{
//             fontSize: { xs: "16px", sm: "20px", md: "24px" },
//             fontWeight: "bold",
//             letterSpacing: { xs: "1.2px", md: "1.8px" },
//             mt: 2,
//             maxWidth: "800px",
//           }}
//         >
//           {description}
//         </Typography>

//         {/* Call-To-Action Button */}
//         {link && (
//           <Button
//             component={Link}
//             to={link}
//             target="_blank"
//             rel="noopener noreferrer"
//             variant="contained"
//             sx={{
//               mt: 4,
//               backgroundColor: "#f68f5c",
//               color: Colors.WHITE,
//               borderRadius: "10px",
//               fontSize: "18px",
//               fontWeight: "bold",
//               textTransform: "none",
//               padding: "12px 24px",
//               "&:hover": {
//                 backgroundColor: "#fd7e14",
//               },
//             }}
//           >
//             Join the Test Series
//           </Button>
//         )}
//       </Grid>

//       {/* Hidden Image for Lazy Loading */}
//       <img
//         ref={imgRef}
//         src={image}
//         alt="background"
//         style={{ display: "none" }}
//         onLoad={() => setIsLoaded(true)}
//       />
//     </Box>
//   );
// };

// export default Header;


import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Colors, Fontfamily } from "../common";
import { Link } from "react-router-dom";

const Header = ({ 
  title, 
  description, 
  image, 
  placeholder, 
  link, 
  target = "",
  buttonText = "Join the Test Series",
  buttonLink = "", 
  overlayColor = "rgba(0, 0, 0, 0.4)", 
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsLoaded(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );

      if (imgRef.current) observer.observe(imgRef.current);

      return () => observer.disconnect();
    } else {
      setIsLoaded(true);
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${isLoaded ? image : placeholder})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: { xs: "scroll", md: "fixed" },
        // height: { xs: "90vh", md: "100vh" },
        height: { xs: "980px", md: "980px" },
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: overlayColor,
        }}
      />

      {/* Content */}
      <Grid
        container
        direction="column"
        alignItems="start"
        textAlign="start"
        sx={{
          position: "relative",
          color: "#fff",
          px: { xs: 2, md: 5 },
          mr: { xs: 2, md:5},
          ml: { xs: 2, md:5},
        }}
      >
        {/* Title */}
        <Typography
          variant="h1"
          sx={{
            fontWeight: "900",
            fontSize: { xs: "35px", sm: "44px", md: "60px" },
            fontFamily: Fontfamily.playfairDisplay,
            letterSpacing: "2px",
            maxWidth: "800px",
          }}
        >
          {title}
        </Typography>

        {/* Subtext */}
        <Typography
          // variant="h6"
          sx={{
            fontSize: { xs: "16px", sm: "20px", md: "18px" },
            fontWeight: "bold",
            letterSpacing: { xs: "1.2px", md: "1.8px" },
            mt: 2,
          }}
        >
          {description}
        </Typography>

        {/* Call-To-Action Button */}
        {link && (
          <Button
            component={Link}
            to={buttonLink || link}
            target={target}
            rel="noopener noreferrer"
            variant="contained"
            sx={{
              mt: 4,
              background: "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)", 
              color: Colors.WHITE,
              borderRadius: "50px",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "none",
              padding: "8px 20px",
              "&:hover": {
                background: "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
              },
            }}
          >
            {buttonText}
          </Button>
        )}
      </Grid>

      {/* Hidden Image for Lazy Loading */}
      <img
        ref={imgRef}
        src={image}
        alt="background"
        style={{ display: "none" }}
        onLoad={() => setIsLoaded(true)}
      />
    </Box>
  );
};

export default Header;
