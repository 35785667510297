import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import Service from "../assets/images/services.jpg";
import Header from "../Components/Header";
import assets from "../assets";
import PageTitle from "../Components/PageTitle";
import { Colors, FontSize, FontWeight, Fontfamily } from "../common";
import "../stylesheets/Home.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

const Services = () => {
  const homeCards = [
    {
      id: 1,
      Logoimage: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-git-branch"
        >
          <line x1="6" y1="3" x2="6" y2="15"></line>
          <circle cx="18" cy="6" r="3"></circle>
          <circle cx="6" cy="18" r="3"></circle>
          <path d="M18 9a9 9 0 0 1-9 9"></path>
        </svg>
      ),
      cardHeading: "Access Controlled",
      cardPara:
        " Manage who sees what, securely. Our access control solutions ensure only the right people have access to your data, keeping your information safe and operations running smoothly",
    },
    {
      id: 2,
      Logoimage: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-users"
        >
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
        </svg>
      ),
      cardHeading: "Team Management",
      cardPara:
        "Streamline your team’s workflow with our advanced team management tools. Enhance collaboration, track progress, and achieve your goals efficiently.",
    },
    {
      id: 3,
      Logoimage: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-code"
        >
          <polyline points="16 18 22 12 16 6"></polyline>
          <polyline points="8 6 2 12 8 18"></polyline>
        </svg>
      ),

      cardHeading: "Code Security",
      cardPara:
        "Protect your software assets with Gatik AI’s robust code security measures. We prioritize shielding your code from potential threats, ensuring your innovations remain safe and sound.",
    },
    {
      id: 4,
      Logoimage: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="feather feather-server"
        >
          <rect x="2" y="2" width="20" height="8" rx="2" ry="2"></rect>
          <rect x="2" y="14" width="20" height="8" rx="2" ry="2"></rect>
          <line x1="6" y1="6" x2="6.01" y2="6"></line>
        </svg>
      ),
      cardHeading: "Zero Configuration",
      cardPara:
        "Simplify your setup process with Gatik AI’s zero-configuration solutions. Start using our solutions instantly, without the need for complex installations or setup processes.",
    },
  ];
  
  return (
    <>
      <PageTitle  
      title="GatikAI Technologies Services | Web & Mobile Development, AI, IoT, SEO & UI/UX" 
      description="GatikTech provides expert Web & Mobile App Development, AI/ML, IoT, UI/UX, SEO, and E-Commerce solutions. From static and dynamic websites to advanced AI-powered applications, we deliver cutting-edge technology solutions for your business success."
      url="services"
      keywords="Web Development, Mobile App Development, IoT Solutions, AI Development, Machine Learning, UI/UX Design, SEO Services, Static Website Design, Dynamic Website Development, E-Commerce Website, Digital Solutions, IT Consulting, GatikTech"
      />
      <Navbar />
      <Header
        title="Our Services"
        description="GatikAI Technologies delivers white-labeled apps, custom web solutions, and reliable backend support to elevate your digital presence"
        image={Service}

      />
      <Container sx={{pt:5,}}>
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 2, md: 1 }}>
            <Box
              component="img"
              src={assets.WebMobile}
              alt="Web / Mobile pic"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: {xs:0, md:5},
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
                gutterBottom
              >
                Web / Mobile
              </Typography>
              <p
                style={{
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                  textAlign: "center"
                }}
              >
                Our expert team is dedicated to crafting innovative,
                user-friendly solutions that drive business growth and enhance
                digital experiences. From custom website development to
                cutting-edge mobile app design, we are committed to providing
                reliable and scalable technology solutions tailored to meet your
                unique needs. Partner with us to transform your digital presence
                and achieve your business goals with seamless efficiency and
                excellence.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mt: {xs:0, md:5},
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
                gutterBottom
              >
                IoT
              </Typography>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                }}
              >
                Our expertise spans the entire IoT ecosystem, from device
                integration and data analytics to cloud computing and real-time
                monitoring. We empower businesses across various industries to
                harness the power of connected devices, streamline operations,
                and drive innovation. With a commitment to quality and customer
                satisfaction, GatikAI Technologies is your trusted partner in
                navigating the complexities of the IoT landscape and unlocking
                the full potential of your technology investments.
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 1, md: 2 }}>
            <Box
              component="img"
              src={assets.IoT}
              alt="IoT pic"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 2, md: 1 }}>
            <Box
              component="img"
              src={assets.AI_ML}
              alt="AI / ML pic"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // padding: "5px",
                // marginTop: 1,
                // mb: { xs: 3, sm: 3, md: 5, lg: 5 },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  // color: "black",
                  mt: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
                gutterBottom
              >
                AI / ML
              </Typography>

              <p
                style={{
                  textAlign: "center",
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                }}
              >
                With a commitment to innovation and excellence, we help
                businesses harness the power of AI/ML to optimize operations,
                enhance decision-making, and unlock new growth opportunities.
                Partner with us to revolutionize your business with the latest
                advancements in AI and ML.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  // color: "black",
                  mt: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
                }}
                gutterBottom
              >
                UI / UX
              </Typography>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                }}
              >
                From comprehensive user research to meticulous interface design,
                we ensure every interaction is seamless and impactful. Partner
                with us to transform your digital presence with top-notch UX
                solutions tailored to your unique needs.
              </p>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Box
              component="img"
              src={assets.UX}
              alt="UI / UX pic"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid item xs={12} md={4} lg={4} order={{ xs: 2, md: 1 }}>
            <Box
              component="img"
              src={assets.DevOps}
              alt="DevOps pic"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            order={{ xs: 1, md: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  // color: "black",
                  mt: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",

                }}
                gutterBottom
              >
                DevOps{" "}
              </Typography>

              <p
                style={{
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                  textAlign: "center"
                }}
              >
                By bridging the gap between development and operations, we help
                your business achieve greater agility, efficiency, and
                scalability. Partner with us to transform your IT infrastructure
                and stay ahead in the competitive digital landscape.
              </p>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 1, lg: 2 }}>
          <Grid
            item
            xs={12}
            md={8}
            lg={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  // color: "black",
                  mt: 5,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: FontSize.thirty,
                  color: Colors.charcoalgray,
                  fontWeight: FontWeight.bold,
                  fontFamily: Fontfamily.poppins,
                  marginBottom: "20px",
                  lineHeight: "1.4",
                  letterSpacing: "1.5px",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",

                }}
                gutterBottom
              >
               SEO
              </Typography>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: Fontfamily.poppins,
                  color: Colors.MediumGray,
                  lineHeight: "1.6",
                  letterSpacing: "1px",
                }}
              >
            Transform your ideas into innovative solutions with our expert software development services. We create custom, scalable applications that boost efficiency, enhance user experience, and help your business stay competitive in an ever-evolving digital world.
              </p>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
            <Box
              component="img"
              src={assets.SEO}
              alt="SEO"
              sx={{
                width: "100%",
                height: "350px",
                borderRadius: "10px",
                backgroundPosition: "center",
              }}
            ></Box>
          </Grid>
        </Grid>
      </Container>
      <Grid container sx={{pt:8}}>
        {homeCards.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={item.id}
            sx={{ pt: 0, pb: 10 }}
          >
            <Box sx={{ height: "100%" }} className="homeCard">
              <Box className="homeCardMedia">{item.Logoimage}</Box>
              <Typography
                variant="h5"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: FontWeight.heading2,
                  fontSize: FontSize.twFour,
                  color: Colors.charcoalgray,
                  letterSpacing: "1.5px",
                }}
              >
                {item.cardHeading}
              </Typography>
              <Typography
                sx={{
                  lineHeight: "1.6rem",
                  letterSpacing: "1.5px",
                  marginTop: "10px",
                  color: Colors.MediumGray,
                  fontFamily: Fontfamily.poppins,
                }}
              >
                {item.cardPara}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Footer />
    </>
  );
};

export default Services;
