import React, { useState } from "react";
import { Button as TalkButton, Modal, message } from "antd";
import {
  Box,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import "../stylesheets/ModelLetsTalk.css";
import axios from "../axiosInstance";
import assets from "../assets";
import {
  AccountCircle,
  Email,
  Phone,
  ChatBubble,
  PhoneInTalk,
} from "@mui/icons-material";

const ModelLetsTalk = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: "91",
    mobile: "",
    mode: "",
    messages: "",
  });
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Field-wise validation
    if (!formData.name) {
      message.error("Please enter your full name.");
      setLoading(false);
      return;
    }
   
    if (!formData.email) {
      message.error("Please enter your email.");
      setLoading(false);
      return;
    }
    if (!formData.mobile) {
      message.error("Please enter your mobile number.");
      setLoading(false);
      return;
    }
    if (!formData.mode) {
      message.error("Please select a mode of communication.");
      setLoading(false);
      return;
    }
    if (!formData.messages) {
      message.error("Please enter your message.");
      setLoading(false);
      return;
    }
  
    try {
      await axios.post(`/api/v1/email/send-lets-talk-email`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      message.success(
        "Thanks for contacting us. We'll get back to you as soon as possible."
      );
  
      // Reset form fields after successful submission
      setFormData({
        name:"",
        email: "",
        countryCode: "91",
        mobile: "",
        mode: "",
        messages: "",
      });
  
      setIsModalOpen(false);
    } catch (error) {
      message.error("There was an error sending the email!");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      <Box className="letstalkmainbox">
        <TalkButton className="responsive-button" onClick={showModal}>
          Let's Talk
        </TalkButton>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={2000}
        >
          <Box>
            <Box sx={{ flexGrow: 0, mt: 5 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <div className="form-container">
                    <form onSubmit={handleSubmit}>
                      <Box className="form-group">
                        <TextField
                          type="text"
                          id="name"
                          variant="outlined"
                          name="name"
                          placeholder="Enter your full name *"
                          className="wide-input"
                          value={formData.name}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle sx={{ color: "#000" }}/>
                              </InputAdornment>
                            ),
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "10px", // Rounded corners
                              },
                            },
                          }}
                        />
                      </Box>
                      <Box className="form-group"  mt={4}>
                        <TextField
                          type="email"
                          id="email"
                          variant="outlined"
                          name="email"
                          placeholder="Company email *"
                          className="wide-input"
                          value={formData.email}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Email sx={{ color: "#000" }} />
                              </InputAdornment>
                            ),
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "10px", // Rounded corners
                              },
                            },
                          }}
                        />
                      </Box>

                      <Box
                        className="mobile"
                        style={{ display: "flex", flexDirection: "row" }}
                        mt={4}
                      >
                        <Box
                          className="form-group"
                          style={{ flex: "0 1 100px" }}
                        >
                          <TextField
                            style={{ marginRight: "8px" }}
                            id="country-code"
                            name="countryCode"
                            label="Code"
                            variant="outlined"
                            required
                            value={formData.countryCode}
                            onChange={handleChange}
                            type="number"
                            sx={{
                              "& input[type='number']": {
                                MozAppearance: "textfield",
                                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#f68f5c",
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment sx={{ color: "#000" }} position="start">
                                  +
                                </InputAdornment >
                              ),
                              sx: {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#f68f5c",
                                  },
                                "&:hover .MuiOutlinedInput": {
                                  borderColor: "gray",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderRadius: "10px", // Rounded corners
                                },
                              },
                            }}
                            inputProps={{ pattern: "[0-9]{1,3}" }}
                          />
                        </Box>

                        <Box className="form-group" style={{ flex: "1" }}>
                          <TextField
                          // mt={5}
                            style={{
                              height: "39px",
                            }}
                            id="mobile"
                            name="mobile"
                            pattern="[0-9]{10}"
                           placeholder="Phone number *"
                            variant="outlined"
                            fullWidth
                            type="number"
                            className="wide-input"
                            value={formData.mobile}
                            onChange={handleChange}
                            sx={{
                              "& input[type='number']": {
                                MozAppearance: "textfield",
                                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                "&.Mui-focused": {
                                  color: "#f68f5c",
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Phone sx={{ color: "#000" }}/>
                                </InputAdornment>
                              ),
                              sx: {
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "#f68f5c",
                                  },
                                "&:hover .MuiOutlinedInput": {
                                  borderColor: "gray",
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderRadius: "10px", // Rounded corners
                                },
                              },
                            }}
                          />
                        </Box>
                      </Box>

                      <TextField
                      mb={2}
                        fullWidth
                        id="mode"
                        select
                         placeholder="Mode of communication *"
                        name="mode"
                        value={formData.mode}
                        onChange={handleChange}
                        margin="normal"
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#f68f5c",
                            },
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneInTalk sx={{ color: "#000" }}/>
                            </InputAdornment>
                          ),
                          sx: {
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#f68f5c",
                            },
                            "&:hover .MuiOutlinedInput": {
                              borderColor: "gray",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: "10px", // Rounded corners
                            },
                          },
                        }}
                      >
                        <MenuItem disabled>Mode of communication</MenuItem>
                        <MenuItem value="Email">Email</MenuItem>
                        <MenuItem value="Phone">Phone</MenuItem>
                      </TextField>

                      <Box className="form-group" mt={3}>
                        <TextField
                          id="messages"
                          name="messages"
                          placeholder="What would you like to discuss?"
                          multiline
                          rows={4}
                          className="wide-input"
                          variant="outlined"
                          fullWidth
                          value={formData.messages}
                          onChange={handleChange}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#f68f5c",
                              },
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment sx={{ mb: 8 }} position="start">
                                <ChatBubble  sx={{ color: "#000" }} />
                              </InputAdornment>
                            ),
                            sx: {
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  borderColor: "#f68f5c",
                                },
                              "&:hover .MuiOutlinedInput": {
                                borderColor: "gray",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "10px", // Rounded corners
                              },
                            },
                          }}
                        />
                      </Box>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        disabled={loading}
                        sx={{
                          background:
                            "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)",
                          color: "#fff",
                          mt: 2,
                          py: 1.5,
                          fontSize: "16px",
                          fontWeight: "bold",
                          borderRadius: "6px",
                          "&:hover": {
                            background:
                              "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
                          },
                        }}
                      >
                        {loading ? "Submitting..." : "Inquire Now"}
                      </Button>
                    </form>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={assets.letsTalk}
                    alt="Description pic"
                    sx={{
                      width: "100%",
                      height: { xs: "auto", sm: "790px" },
                      backgroundPosition: "center",
                    }}
                  ></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default ModelLetsTalk;
