// export default {
//   eight: 8,
//   ten: 10,
//   twelve: 10,
//   fourteen: 14,
//   fifteen: 15,
//   sixteen: 16,
//   seventeen: 17,
//   eighteen: 18,
//   twenty: 20,
//   twFour: 24,
//   twNine: 29,
//   thirty: 30,
//   thirtyTwo: 32,
//   fourty: 40,
// };

const FontSize = {
  eight: 8,
  ten: 10,
  twelve: 12,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  twenty: 20,
  twFour: 24,
  twNine: 29,
  thirty: 30,
  thirtyTwo: 32,
  fourty: 40,
};
export default FontSize
