import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import {
  AccountCircle,
  Email,
  Phone,
  ChatBubble,
  AttachFile,
} from "@mui/icons-material";
import { message } from "antd";
import axios from "../axiosInstance";
import { FontWeight, Colors, Fontfamily, FontSize } from "../common";

const Inquiry = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    countryCode: "91",
    discussion: "",
    file: null,
  });

  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFormData({ ...formData, file });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    // Form validation
    if (!formData.name) {
      message.error("Please enter your name.");
      setLoading(false);
      return;
    }
    if (!formData.email) {
      message.error("Please enter your email.");
      setLoading(false);
      return;
    }
    if (!formData.phone) {
      message.error("Please enter your phone number.");
      setLoading(false);
      return;
    }
    if (!formData.discussion) {
      message.error("Please enter a discussion message.");
      setLoading(false);
      return;
    }
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.countryCode + formData.phone);
    formDataToSend.append("discussion", formData.discussion);
    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    try {
      await axios.post("/api/v1/email/send-inquiry-email", formDataToSend, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      message.success("Inquiry submitted successfully!");

      // Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        countryCode: "+91",
        discussion: "",
        file: null,
      });
      setFileName("");
    } catch (error) {
      message.error("Failed to submit inquiry. Please try again.");
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mt: 8, mb: 10, ml: { xs: 1, md: 10 }, mr: { xs: 1, md: 10 } }}>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          letterSpacing: "1.5px",
          fontWeight: FontWeight.bold,
          color: Colors.charcoalgray,
          fontSize: FontSize.thirty,
          fontFamily: Fontfamily.poppins,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          zIndex: 2,
          position: "relative",
        }}
        gutterBottom
      >
        Tell us about your project
      </Typography>

      <Typography
        paragraph
        sx={{
          ml: 2,
          mr: 2,
          textAlign: "center",
          color: Colors.MediumGray,
          fontFamily: Fontfamily.poppins,
          letterSpacing: "1.5px",
          fontSize: FontSize.fifteen,
          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
          zIndex: 2,
          position: "relative",
        }}
      >
        Have a web or mobile app project in mind? Let’s discuss making your
        project a reality.
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper elevation={2} sx={{ p: 4, width: "90%", borderRadius: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                // label="Enter your full name"
                placeholder="Enter your full name *"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#f68f5c",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "&:hover .MuiOutlinedInput": {
                      borderColor: "gray",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px", // Rounded corners
                    },
                  },
                }}
                sx={{ mb: 3 }}
              />
              <TextField
                fullWidth
                // label="Company email"
                placeholder="Company email *"
                variant="outlined"
                name="email"
                required
                value={formData.email}
                onChange={handleChange}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#f68f5c",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "&:hover .MuiOutlinedInput": {
                      borderColor: "gray",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px", // Rounded corners
                    },
                  },
                }}
                sx={{ mb: 3 }}
              />
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Code"
                    name="countryCode"
                    required
                    value={formData.countryCode}
                    onChange={handleChange}
                    type="number"
                    sx={{
                      "& input[type='number']": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#f68f5c",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment sx={{ color: "#000" }} position="start">
                          +
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "&:hover .MuiOutlinedInput": {
                          borderColor: "gray",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px", // Rounded corners
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    // label="Phone number "
                    placeholder="Phone number *"
                    variant="outlined"
                    name="phone"
                    required
                    value={formData.phone}
                    onChange={handleChange}
                    type="number"
                    sx={{
                      "& input[type='number']": {
                        MozAppearance: "textfield",
                        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                          {
                            display: "none",
                          },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#f68f5c",
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Phone sx={{ color: "#000" }} />
                        </InputAdornment>
                      ),
                      sx: {
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#f68f5c",
                        },
                        "&:hover .MuiOutlinedInput": {
                          borderColor: "gray",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "10px", // Rounded corners
                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                placeholder="What would you like to discuss?"
                // label="What would you like to discuss?"
                variant="outlined"
                multiline
                rows={3}
                name="discussion"
                value={formData.discussion}
                onChange={handleChange}
                sx={{ mb: 3 }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#f68f5c",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ mb: 5.3 }} position="start">
                      <ChatBubble sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "&:hover .MuiOutlinedInput": {
                      borderColor: "gray",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px", // Rounded corners
                    },
                  },
                }}
              />

              {/* File Upload */}
              <Box
                sx={{
                  border: "2px dashed gray",
                  borderRadius: 2,
                  textAlign: "center",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f9f9f9" },
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#f68f5c",
                    },
                  },
                }}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="file-upload"
                  onChange={handleFileUpload}
                  InputLabelProps={{
                    sx: {
                      "&.Mui-focused": {
                        color: "#f68f5c",
                      },
                    },
                  }}
                />
                <label htmlFor="file-upload">
                  <AttachFile sx={{ fontSize: 30, color: "gray" }} />
                  <Typography variant="body1" sx={{ color: "gray" }}>
                    {fileName || "Browse to drop file here"}
                  </Typography>
                </label>
              </Box>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={loading}
                sx={{
                  background:
                    "linear-gradient(135deg, #FF716E 0%, #FF5733 100%)",
                  color: "#fff",
                  mt: 2,
                  py: 1.5,
                  fontSize: "16px",
                  fontWeight: "bold",
                  borderRadius: "6px",
                  "&:hover": {
                    background:
                      "linear-gradient(135deg, #FF716E 0%, #F68f5c 100%)",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "#fff" }} />
                ) : (
                  "Inquire now"
                )}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default Inquiry;
