import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  Box,
  Grid,
  CircularProgress,
  TextField,
  Button,
  List,
  ListItem,
  InputAdornment,
  Divider,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SearchIcon from "@mui/icons-material/Search";

import Header from "../Components/Header";
import PageTitle from "../Components/PageTitle";
import { client } from "../Contentful/Client";
import Blogimage from "../assets/images/blogImage.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";

const BlogPost = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const entries = await client.getEntries({ content_type: "blog" });
        setBlogs(entries.items);
        setFilteredBlogs(entries.items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "25%",
        }}
      >
        <CircularProgress style={{ color: "#f68f5c" }} />
      </p>
    );
  }

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearch = () => {
    if (searchTerm === "") {
      setFilteredBlogs(blogs);
    } else {
      // Filter blogs based on the search term
      const filtered = blogs.filter((item) =>
        item?.fields?.blogTitle.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBlogs(filtered);
    }
  };

  const totalPages = Math.ceil(filteredBlogs?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredBlogs?.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const visiblePages = (pageNumbers) => {
    const range = 3;
    const start = Math.max(0, currentPage - range);
    const end = Math.min(totalPages, currentPage + range);
    return pageNumbers.slice(start, end);
  };

  return (
    <Box>
      <PageTitle
        title="GatikAI Technologies Blog | Insights on AI, IoT, Web & App Development"
        description="Stay updated with the latest trends in AI, IoT, Web & App Development, SEO, and more. Explore expert insights, tutorials, and industry updates from GatikAI Technologies."
        url="blogs"
        keywords="GatikAI Blog, AI Insights, IoT Trends, Web Development Tips, Mobile App Development, SEO Strategies, Tech Industry News, Software Development Blogs, Digital Transformation"
      />
      <Navbar />
      <Header
        // title="Our insights into technology and culture"
        // description="Explore our blog for the latest insights, tips, and trends in technology. Stay updated with expert advice and actionable strategies. Empower your business and stay ahead in a competitive market."
        title="Tech & Business Insights"
        description="Explore our blog for the latest tech trends, tips, and expert strategies to help you stay ahead in the competitive market"
        image={Blogimage}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          ml: 3,
          mr: 3,
          pb: 8,
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ mt: 3 }}>
          {/* Grid for Blog Posts */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              {paginatedData?.map((item, index) => {
                const formattedDate = moment(item?.fields?.blogPostDate).format(
                  "MMMM DD, YYYY"
                );
                return (
                  <Grid item xs={12} md={12} key={index}>
                    <Card
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        overflow: "hidden",
                        padding: "15px",
                        // height: "300px",
                      }}
                    >
                      {/* Image Section */}
                      <CardMedia
                        component="img"
                        sx={{
                          width: { xs: "100%", md: "50%" },
                          height: "250px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          transition: "transform 0.5s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.05)",
                          },
                        }}
                        // image={item?.fields?.blogImage?.fields?.file?.url}
                        // alt={item?.fields?.blogImage?.fields?.file?.title}
                        image={item?.fields?.blogImage?.fields?.file?.url.replace(
                          /^http:\/\//,
                          "https://"
                        )}
                        alt={item?.fields?.blogImage?.fields?.file?.title}
                      />

                      {/* Text Section */}
                      <CardContent
                        sx={{
                          width: { xs: "100%", md: "50%" },
                          padding: 3,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{
                            mb: 2,
                            fontWeight: "bold",
                            fontSize: "20px",
                            letterSpacing: "1.5px",
                            fontFamily: "Poppins, sans-serif",
                            color: "rgba(9, 8, 8, 0.68)",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 2,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ml: { xs: -2, md: 0 },
                            mr: { xs: 2, md: 0 },
                          }}
                        >
                          {/* <Link
                            to={{
                              pathname: `/blogs/${item.fields?.blogTitle
                                .replace(/\s+/g, "-")
                                .toLowerCase()}`,
                            }}
                            state={{ id: item.fields?.blogId }}
                            style={{
                              textDecoration: "none",
                              color: "rgba(9, 8, 8, 0.68)",
                            }}
                          >
                            {" "}
                            {item?.fields?.blogTitle}
                          </Link> */}
                          <Link
                           to={`/blogs/${item.fields?.blogTitle.replace(/\s+/g, "-").toLowerCase()}-${item.fields?.blogId}`}  // ID & Title URL me jaayenge
                            style={{
                              textDecoration: "none",
                              color: "rgba(9, 8, 8, 0.68)",
                            }}
                          >
                            {item?.fields?.blogTitle}
                          </Link>
                        </Typography>

                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            mb: 3,
                            display: "flex",
                            alignItems: "center",
                            ml: { xs: -2, md: 0 },
                            mr: { xs: 2, md: 0 },
                          }}
                        >
                          <CalendarMonthOutlinedIcon
                            sx={{ mr: 1, color: "#f68f5c" }}
                          />{" "}
                          {formattedDate}
                        </Typography>

                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            mb: 5,
                            letterSpacing: "1.5px",
                            fontFamily: "Poppins, sans-serif",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 3,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            ml: { xs: -2, md: 0 },
                            mr: { xs: 2, md: 0 },
                          }}
                        >
                          {item?.fields?.blogDisplayTitle}
                        </Typography>

                        <Box sx={{ mt: "auto" }}>
                          {/* <Link
                            to={{
                              pathname: `/blogs/${item.fields?.blogTitle
                                .replace(/\s+/g, "-")
                                .toLowerCase()}`,
                            }}
                            state={{ id: item.fields?.blogId }}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              variant="button"
                              sx={{
                                color: "#f68f5c",
                                letterSpacing: "1.5px",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "bold",
                                "&:hover": { textDecoration: "underline" },
                                ml: { xs: -2, md: 0 },
                                mr: { xs: 2, md: 0 },
                              }}
                            >
                              Read More
                            </Typography>
                          </Link> */}
                          <Link
                           to={`/blogs/${item.fields?.blogTitle.replace(/\s+/g, "-").toLowerCase()}-${item.fields?.blogId}`} 
                            style={{
                              textDecoration: "none",
                              color: "rgba(9, 8, 8, 0.68)",
                            }}
                          >
                            <Typography
                              variant="button"
                              sx={{
                                color: "#f68f5c",
                                letterSpacing: "1.5px",
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: "bold",
                                "&:hover": { textDecoration: "underline" },
                                ml: { xs: -2, md: 0 },
                                mr: { xs: 2, md: 0 },
                              }}
                            >
                              Read More
                            </Typography>
                          </Link>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {/* Grid for Search and Recent Posts */}
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              mt: 3,
              border: "1px solid #ddd",
              padding: 2,
              borderRadius: "8px",
              ml: 3,
              mr: 3,
            }}
          >
            {/* Search Section with Border */}
            <Box sx={{ mb: 4 }}>
              <TextField
                label="Search"
                variant="outlined"
                placeholder="Search By Blog Title"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  marginBottom: 2,
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "#f68f5c",
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#f68f5c",
                    },
                    "&:hover .MuiOutlinedInput": {
                      borderColor: "gray",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "10px", // Rounded corners
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#f68f5c",
                  "&:hover": {
                    backgroundColor: "#f68f5c",
                  },
                }}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>

            {/* Recent Posts Section with Border */}
            <Typography
              variant="h6"
              sx={{ fontWeight: "600", color: "rgb(56, 54, 54)", mb: 2 }}
            >
              Recent Posts
            </Typography>
            <Divider sx={{ mt: 1, backgroundColor: "rgba(0, 0, 0, 0.6)" }} />
            <List>
              {blogs?.slice(0, 5).map((item, index) => {
                const formattedDate = moment(item?.fields?.blogPostDate).format(
                  "MMMM DD, YYYY"
                );
                return (
                  <ListItem key={index} sx={{ mb: 2 }}>
                    <Link
                       to={`/blogs/${item.fields?.blogTitle.replace(/\s+/g, "-").toLowerCase()}-${item.fields?.blogId}`}
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "15px",
                          letterSpacing: "1.5px",
                          fontFamily: "Poppins, sans-serif",
                          "&:hover": {
                            color: "#f68f5c",
                            transition: "color 0.3s ease",
                          },
                        }}
                      >
                        {item?.fields?.blogTitle}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        {formattedDate}
                      </Typography>
                      <Divider
                        sx={{ mt: 3, backgroundColor: "rgba(0, 0, 0, 0.6)" }}
                      />
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>

        <Box sx={{ mt: 8 }}>
          {filteredBlogs?.length > 0 && !loading && (
            <div className="pagination">
              {/* <div className="pagination" style={{ display: "flex", justifyContent: "flex-start", gap: "5px" }}> */}
              <button
                onClick={() => handlePagination(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>

              {currentPage > 4 && (
                <button onClick={() => handlePagination(1)}>1</button>
              )}
              {currentPage > 5 && <span>...</span>}

              {visiblePages(pageNumbers).map((page) => (
                <button
                  key={page}
                  onClick={() => handlePagination(page)}
                  className={currentPage === page ? "active" : ""}
                >
                  {page}
                </button>
              ))}

              {currentPage < totalPages - 4 && <span>...</span>}
              {currentPage < totalPages - 3 && (
                <button onClick={() => handlePagination(totalPages)}>
                  {totalPages}
                </button>
              )}

              <button
                onClick={() => handlePagination(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default BlogPost;
