import React from "react";
import IndustriesServe from "../Components/IndustriesServe";
import DesignDevelopment from "../Components/DesignDevelopment";
import OurFeatures from "../Components/OurFeatures";
import WorkProcess from "../Components/WorkProcess";
import StaffImage from "../assets/images/HomePage.jpg";
import PageTitle from "../Components/PageTitle";
import OurClient from "../Components/OurClient";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Blog from "../Components/Blog";
import Testimonial from "../Components/Testimonial";
import Inquiry from "../Components/Inquiry";

export default function Home() {
  return (
    <>
      <Navbar />
      <PageTitle
        title="GatikAI Technologies - AI-Powered Web & App Development | IoT & IT Solutions"
        description="GatikAI specializes in custom Web & App Development, AI/ML, IoT, UI/UX, and SEO solutions. Transform your business with cutting-edge technology and expert IT services. Contact us today!"
        url=""
        Keywords="Web Development, Mobile App Development, AI Solutions, Machine Learning, IoT, DevOps, SEO Services, UI/UX Design, Digital Transformation, IT Consulting, Cloud Solutions, GatikTech, Jaipur, India"
      />
      <Header
        title="Innovate. Scale. Succeed"
        description="We provide cutting-edge IT solutions to accelerate your business growth—tailored for startups & enterprises alike"
        image={StaffImage}
        buttonText="Know More"
        link="/about"
      />
      {/* <DesignDevelopment />
      <IndustriesServe />
      <WorkProcess />
      <OurFeatures />
      <Blog />
      <OurClient />
      <Testimonial />
      <Footer /> */}

      {/* <OurFeatures /> */}
      <DesignDevelopment />
      <IndustriesServe />
      <WorkProcess />
      <OurClient />
      <Blog />
      <Inquiry />
      <Testimonial />
      <Footer />
    </>
  );
}
