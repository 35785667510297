import React from "react";
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import { Colors, FontSize, Fontfamily, FontWeight } from "../common";
import assets from "../assets";

const IndustriesCards = [
  {
    id: 1,
    image: assets.Education,
    industriescardHeading: "Education",
    industriescardPara:
      "We revolutionize education with AI-driven solutions that enhance learning experiences and improve outcomes for students and educators alike.",
  },
  {
    id: 2,
    image: assets.HealthFitness,
    industriescardHeading: "Health & Fitness",
    industriescardPara:
      "Our AI technology helps individuals and organizations achieve their health and fitness goals through personalized plans and real-time analytics.",
  },
  {
    id: 3,
    image: assets.SocialNetworking,
    industriescardHeading: "Social Networking",
    industriescardPara:
      "We create intelligent social networking platforms that foster connections and engagement through advanced AI algorithms.",
  },
  {
    id: 4,
    image: assets.OTT,
    industriescardHeading: "OTT & VOD",
    industriescardPara:
      "Our AI solutions transform OTT and VOD services, offering personalized content recommendations and enhancing viewer experiences.",
  },
  {
    id: 5,
    image: assets.Travel,
    industriescardHeading: "Travel & Tourism",
    industriescardPara:
      "We enhance travel experiences with AI-driven insights, personalized recommendations, and efficient itinerary planning.",
  },
  {
    id: 6,
    image: assets.Transport,
    industriescardHeading: "Transport",
    industriescardPara:
      "Our AI solutions improve transportation systems with real-time data analytics, optimizing routes and reducing costs.",
  },
  {
    id: 7,
    image: assets.Ecommerce,
    industriescardHeading: "E-commerce",
    industriescardPara:
      "We boost e-commerce businesses with AI-powered tools for personalized shopping experiences, inventory management, and customer insights.",
  },
  {
    id: 8,
    image: assets.FoodGrocery,
    industriescardHeading: "Food and Grocery",
    industriescardPara:
      "Our AI technology helps food and grocery businesses optimize supply chains, reduce waste, and enhance customer experiences.",
  },
];

const IndustriesServe = () => {
  return (
    <Box component="section" aria-labelledby="industries-title">
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          letterSpacing: "1.5px",
          fontWeight: FontWeight.bold,
          color: Colors.charcoalgray,
          fontSize: FontSize.thirty,
          fontFamily: Fontfamily.poppins,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
        }}
        gutterBottom
      >
        Diverse Industries We Work With
      </Typography>
      <Typography
        paragraph
        sx={{
          ml: 2,
          mr: 2,
          mb: 4,
          textAlign: "center",
          color: Colors.MediumGray,
          fontFamily: Fontfamily.poppins,
          letterSpacing: "1.5px",
          fontSize: FontSize.fifteen,
          textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
        }}
      >
        At Gatik AI, we're proud to work with a wide range of industries, each
        with its unique challenges and opportunities.
      </Typography>
      <Grid container spacing={6} sx={{ px: { xs: 3, md: 5 }, pt: 4 }}>
  {IndustriesCards.map((item) => (
    <Grid item xs={12} sm={6} md={3} key={item.id}>
      <Card
        sx={{
          height: "100%", 
          display: "flex",
          flexDirection: "column",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          overflow: "hidden",
          transition: "transform 0.3s ease",
          "&:hover": { transform: "scale(1.05)" },
        }}
      >
        <CardMedia
          component="img"
          height="140"
          image={item.image}
          alt={item.industriescardHeading}
         sx={{
          // width: "100%",
          objectFit: "inherit !important",
          display: "block",
          margin: "auto",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
         }}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 700,
              fontSize: "1.25rem",
              mb: 1,
              color: "#333",
              fontFamily: Fontfamily.poppins,
              letterSpacing: "1.5px",
              textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
            }}
          >
            {item.industriescardHeading}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: Fontfamily.poppins,
              color: Colors.MediumGray,
              lineHeight: "1.6",
              letterSpacing: "1px",
            }}
          >
            {item.industriescardPara}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

    </Box>
  );
};

export default IndustriesServe;
