import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import ModalLetsTalk from "../Components/ModalLetsTalk";
import "../stylesheets/Navbar.css";
import Mainlogo from "../assets/images/Logo.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const menus = [
    "home",
    "about",
    "services",
    "product",
    "testimonials",
    "blogs",
    "contact",
  ];
  const pagesWithNoImages = [
    "/privacyandpolicy",
    "/termsandconditions",
    "/career",
  ]; // Add pages without images

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    // Preload the logo image
    const link = document.createElement("link");
    link.rel = "preload";
    link.href = Mainlogo; // Path to the logo image
    link.as = "image";
    document.head.appendChild(link);

    // Clean up the preload link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isNoImagePage = pagesWithNoImages.includes(location.pathname);

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);

      // Always set scrolled to true for pages without images
      setScrolled(isNoImagePage || currentScrollPos > 120);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, location.pathname]);

  const navbarStyleCon = {
    top: visible ? "0" : "-60px",
    transition: "top 0.9s, background-color 0.9s, box-shadow 0.9s",
    width: "100%",
    position: "fixed",
    zIndex: "100",
    backgroundColor: scrolled ? "#FFF" : "transparent",
    boxShadow: scrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
    display: "flex",
    height: "100px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    boxSizing: "border-box",
    top: 0,
  };

  const navbarLinkStyle = {
    color: scrolled ? "#000" : "#FFF",
    cursor: "pointer",
  };

  const navbarStyleMobile = {
    position: "fixed",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  };

  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const productMenu = (
    <Menu
    className="product-menu"
      items={[
        {
          key: "1",
          label: (
            <Link
              to="/products/testseries"
              onClick={() => setIsOpen(false)}
              aria-label="Go to Test Series page"
            >
              Test Series
            </Link>
          ),
        },
        // {
        //   key: "2",
        //   label: (
        //     <Link
        //       to="/products/liveIt"
        //       onClick={() => setIsOpen(false)}
        //       aria-label="Go to LiveIt page"
        //     >
        //       LiveIt
        //     </Link>
        //   ),
        // },
      ]}
    />
  );

  return (
    <>
      <Box className="header" style={navbarStyleCon}>
        <Box className="nav-logo-parent">
          <Link to="/" aria-label="Go to home page">
            <img
              src={Mainlogo}
              alt="logo"
              style={{ height: "50px", width: "165px" }}
            />
          </Link>
        </Box>
        <Box>
          <ul className="links">
            {menus.map((item, index) => {
              if (item === "product") {
                return (
                  <li key={item} className="nav-links-dropdown">
                    <Dropdown
                      overlay={productMenu}
                      trigger={["click"]}
                      aria-label="Toggle Product dropdown"
                    >
                      <div
                        className={`nav-links ${
                          location.pathname.includes("/product") ? "active" : ""
                        }`}
                        style={navbarLinkStyle}
                      >
                        Product
                      </div>
                    </Dropdown>
                  </li>
                );
              }

              return (
                <li key={item}>
                  <Link
                    style={navbarLinkStyle}
                    to={index === 0 ? "/" : "/" + item}
                    className={`nav-links ${
                      location.pathname === (index === 0 ? "/" : "/" + item)
                        ? "active"
                        : ""
                    }`}
                    aria-label={`Go to ${item
                      .charAt(0)
                      .toUpperCase()}${item.slice(1)} page`}
                  >
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </Link>
                </li>
              );
            })}
          </ul>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            className="toggle_btn"
            onClick={handleToggleClick}
            style={navbarLinkStyle}
            aria-label={isOpen ? "Close mobile menu" : "Open mobile menu"}
            aria-expanded={isOpen}
          >
            {isOpen ? (
              <ClearIcon style={navbarLinkStyle} />
            ) : (
              <MenuIcon style={navbarLinkStyle} />
            )}
          </Box>
          <Box className="LetstalkModel">
            <ModalLetsTalk />
          </Box>
        </Box>
      </Box>

      <Box
        style={navbarStyleMobile}
        className={`dropdown_menu ${isOpen ? "open" : "false"}`}
        aria-hidden={!isOpen}
      >
        {menus.map((item, index) => (
          <li key={item} style={{ cursor: "pointer" }}> 
            {item === "product" ? (
              <Dropdown overlay={productMenu} trigger={["click"]}>
                <div
                  className={`nav-links ${
                    location.pathname.includes("/product") ? "active" : ""
                  }`}
                  aria-label="Open Product dropdown"
                >
                  Product
                </div>
              </Dropdown>
            ) : (
              <Link
                to={index === 0 ? "/" : "/" + item}
                className={`nav-links ${
                  location.pathname === (index === 0 ? "/" : "/" + item)
                    ? "active"
                    : ""
                }`}
                onClick={handleToggleClick}
                aria-label={`Go to ${item.charAt(0).toUpperCase()}${item.slice(
                  1
                )} page`}
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </Link>
            )}
          </li>
        ))}
      </Box>
    </>
  );
};

export default Navbar;
