import React from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
} from "@mui/material";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import PageTitle from "../Components/PageTitle";
import assets from "../assets";
import Footer from "../Components/Footer";
import StarIcon from "@mui/icons-material/Star";
import { FontSize, Fontfamily, Colors } from "../common";
import OurClient from "../Components/OurClient";

const Testimonials = () => {
  const testimonials = [
    {
      company: " Navv Systems ",
      projectName: "Navv",
      comments: `GATIKAI has been a reliable and forward-thinking partner in the NavvSystem project.   Collaborating with them on the NavvSystem project has been a highly rewarding experience. Their team exhibited exceptional technical acumen, strategic problem-solving capabilities, and a strong commitment to delivering excellence. Their proactive approach and meticulous attention to detail played a pivotal role in the project's success. We highly appreciate their expertise and look forward to future collaborations`,
      ratings: [5, 5, 5, 5, 5, 5],
    },

    {
      company: "FYF",
      projectName: "FYF",
      comments: `Working with GATIKAI on the FYF project was a fantastic experience. Their team demonstrated exceptional technical expertise, professionalism, and commitment to delivering high-quality solutions. The collaboration was seamless, and their innovative approach truly added value to the project. I highly recommend GATIKAI for their dedication and technical excellence.`,
      ratings: [5, 5, 4, 5, 5, 5],
    },

    {
      company: "Idealogic Technologies SDN BHD",
      projectName: "iPoultry",
      comments: `Our experience working with GATIKAI on the iPoultry project was truly commendable. Their team exhibited a strong understanding of the domain, delivering innovative and efficient solutions tailored to our needs. Their professionalism, technical expertise, and proactive approach made the collaboration smooth and highly productive. We appreciate their dedication and look forward to future collaborations.`,
      ratings: [5, 5, 5, 4, 4, 5],
    },
    // Add more testimonials as needed
  ];

  const ratingsLabels = [
    "Skills",
    "Quality of work",
    "Availability",
    "Adherence to Schedule",
    "Communication",
    "Cooperation",
  ];

  return (
    <>
      <PageTitle title="Client Testimonials | GatikAI Technologies Success Stories & Reviews" 
      description="See what our clients say about GatikTech! Read testimonials and success stories from businesses that have benefited from our expert Web & Mobile Development, AI, IoT, and SEO solutions."
      url="testimonials"
      keywords="GatikTech Testimonials, Client Reviews, Success Stories, Customer Feedback, Web Development Reviews, AI/ML Solutions Testimonials, IoT Services Feedback, SEO Service Reviews, Software Development Experience, GatikTech Customer Stories"
      />
      <Navbar />
      <Header
        title="What Our Clients Say"
        description="See how GatikAI Technologies has made a difference. Our clients share their success stories and the impact of our solutions on their businesses"
        image={assets.TestimonialsImage}
      />

      {testimonials.map((testimonial, index) => (
        <Card
          key={index}
          sx={{
            ml: { md: 10, xs: 3 },
            mr: { md: 10, xs: 3 },
            p: 2,
            borderRadius: 3,
            boxShadow: 3,
            mt: 5,
            mb: 5,

            transition: "transform 0.3s ease",
            "&:hover": { transform: "scale(1.05)" },
          }}
        >
          <Grid container spacing={3} alignItems="center">
            {/* Left Side - Avatar & Company */}
            <Grid item xs={12} md={8} display="flex" alignItems="center">
              <Grid item xs={12} md={10}>
                <Box display="flex" alignItems="center">
                  <Avatar sx={{ width: 60, height: 60, mr: 2 }} />
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{
                      // color: Colors.WHITE,
                      fontFamily: Fontfamily.poppins,
                      fontSize: FontSize.seventeen,
                      letterSpacing: "1.2px",
                      textShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {testimonial.company}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  mt={2}
                  style={{
                    fontWeight: "normal",
                    fontFamily: Fontfamily.poppins,
                    letterSpacing: "1.5px",
                  }}
                >
                  Project Name:{" "}
                  <span
                    style={{
                      fontWeight: "normal",
                      fontFamily: Fontfamily.poppins,
                      letterSpacing: "1.5px",
                    }}
                  >
                    {testimonial.projectName}
                  </span>
                </Typography>
                <Typography
                  variant="subtitle1"
                  mt={1}
                  sx={{
                    fontWeight: "bold",
                    fontFamily: Fontfamily.poppins,
                    letterSpacing: "1.5px",
                  }}
                >
                  Suggestions/Comments:
                </Typography>
                <Typography
                  variant="body2"
                  mt={1}
                  sx={{
                    fontFamily: Fontfamily.poppins,
                    color: Colors.MediumGray,
                    lineHeight: "1.6",
                    letterSpacing: "1px",
                  }}
                >
                  {testimonial.comments}
                </Typography>
              </Grid>
            </Grid>

            {/* Right Side - Ratings */}
            <Grid item xs={12} md={3}>
              {ratingsLabels.map((label, i) => (
                <Box
                  key={i}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      fontFamily: Fontfamily.poppins,
                      color: Colors.MediumGray,
                      lineHeight: "1.6",
                      letterSpacing: "1px",
                      fontSize: FontSize.fifteen,
                    }}
                  >
                    {label}:
                  </Typography>
                  <Box>
                    {[...Array(5)].map((_, j) => (
                      <StarIcon
                        key={j}
                        sx={{
                          color:
                            j < testimonial.ratings[i] ? "#FFA500" : "#ddd",
                          fontFamily: Fontfamily.poppins,
                          letterSpacing: "1.5px",
                          fontSize: FontSize.fifteen,
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Card>
      ))}

      <OurClient />
      <Footer />
    </>
  );
};

export default Testimonials;
