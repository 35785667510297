import React, { useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router-dom";
import PageTitle from "../Components/PageTitle";
import { Colors, FontSize, FontWeight, Fontfamily } from "../common";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Terms from "../assets/images/Terms.png";

const TermsandConditions = () => {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 150);
    }, [pathname]);

    return null;
  };
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <PageTitle
        title="Terms and Conditions for Using GatikAI Technologies Service's"
        description="Read the Terms and Conditions for using GatikTech's website and services. Learn about user responsibilities, intellectual property rights, limitations of liability, and governing laws."
        url="termsandconditions"
        keywords="GatikTech Terms and Conditions, Website Terms, IT Services Agreement, Service Terms, User Responsibilities, Intellectual Property, Legal Terms, Privacy Policy, Limitation of Liability, Governing Law"
      />
      <Header
        title="Terms and Conditions of Use"
        description=""        
        image={Terms}
      />
      <Box
        sx={{
          minHeight: "auto",
          mt: 5,
          ml: { xs: 2, md: 10 },
          mr: { xs: 2, md: 10 },
          mb: 8,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            letterSpacing: "1.5px",
            fontWeight: FontWeight.bold,
            color: Colors.charcoalgray,
            fontSize: FontSize.thirty,
            fontFamily: Fontfamily.poppins,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          Terms and Conditions
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten,  mt: 4  }}
          />{" "}
          No material from this site may be copied, modified, reproduced,
          republished, uploaded, transmitted, posted or distributed in any form
          without prior written permission from GatikAI Technologies. All rights
          not expressly granted herein are reserved.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          The GatikAI Technologies name and logo are trademarks that belong to
          and are owned by GatikAI Technologies. These trademarks may not be
          used in any manner without prior written consent from GatikAI
          Technologies.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          Unauthorized use of the materials appearing on this Site may violate
          copyright, trademark and other applicable laws. Such use could result
          in criminal or civil penalties. GatikAI Technologies may monitor
          access to the Site.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          GatikAI Technologies may terminate a User’s access to the Site at any
          time and for any reason. The provisions regarding disclaimer of
          warranty, accuracy of information and indemnification shall survive
          such termination.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          GatikAI Technologies may change or discontinue any aspect of the Site
          at any time, including its content or features.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          GatikAI Technologies shall not be liable for damages of any kind,
          including and without limitation to direct, incidental or
          consequential damages (including, but not limited to, damages for lost
          profits, business interruption, and loss of programs or information)
          arising out of the use of or inability to use the Site, any
          information provided on the Site, or for any other reason whatsoever.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          The Site provides links to websites and access to content, products
          and services from third parties, including Users, affiliates and
          sponsors of the Site. GatikAI Technologies is not responsible for the
          availability of, or content provided on, third party websites. Before
          accessing or using such websites, GatikAI Technologies encourages
          Users to read their policies regarding privacy and other topics.
          GatikAI Technologies is not responsible for third party content
          accessible through the Site, including opinions, advice, statements
          and advertisements. The User shall bear all risks associated with the
          use of such content. GatikAI Technologies is not responsible for any
          loss or damages the User may incur from dealing with any third party.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          GatikAI Technologies makes no warranty that: (a) the Site will meet
          the User’s requirements; (b) the Site will be available on an
          uninterrupted, timely, secure or error-free basis; (c) the results
          that may be obtained from the use of the Site or any services offered
          through the Site will be accurate or reliable.
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mt: 2,
            lineHeight: "1.7",
            letterSpacing: "1px",
            fontFamily: Fontfamily.poppins,
          }}
        >
          <FiberManualRecordIcon
            sx={{ color: "#000", fontSize: FontSize.ten }}
          />{" "}
          GatikAI Technologies may at any time revise this terms of service.
          Users are bound by the most current terms of service each time they
          visit this Site. GatikAI Technologies encourages Users to periodically
          review these terms of service to which they are bound.
        </Typography>
      </Box>
      <Footer />
    </>
  );
};

export default TermsandConditions;
